@import url(https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap);
body {
  margin: 0;
  font-family: "Albert Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
}

code {
  font-family: "Albert Sans", sans-serif !important;
}

/* this is a error fallback css  */
.cross-center{
  align-items: center;
}
.col-flex{
  flex-direction: column;
}
.error-message{
  margin:-50px -40px;
  background-color: #fff;
  min-height: 100vh;
  padding-top:100px;
  align-self: stretch;
}
.react-error-image{
  width:350px;
  height: auto;
}
.react-error-image img{
  width: 100%;
  height:auto;
}
.error-action{
  margin-top: 40px;
}
.valetCard-remove-btn {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 180px;
  height: 34px;
  border-radius: 10px;
  cursor: pointer;
  margin: 25px 0px 0px 0px;  
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* mini slider style for vehicle group images */

.slider-mini-vg .slick-arrow {
  z-index: 4;
}
.slider-mini-vg .slick-arrow.slick-prev {
  left: 0rem;
}
.slider-mini-vg .slick-arrow.slick-next {
  right: 0rem;
}
.slider-mini-vg .slick-dots li {
  width: 10px;
  margin: 0;
}
.slider-mini-vg .slick-dots li button, .slider-mini-vg .slick-dots li button::before  {
  width: 10px;
}
.carauselContainer {
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.field-error-message {
  color: #d13135;
  font-size: 0.75rem;
  margin-top: 3px;
  margin-left: 12px;
  display: block;
  font-weight: 400;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* language button style */
#localize-widget {
  z-index: 1200 !important;
}
#localize-widget.localize-right-top {
  right: 30% !important;
  top: 1.8% !important;
  border-radius: 10px !important;
}

#localize-active-lang {
  font-size: 12px !important;
  text-decoration: none;
  text-align: center!important;
  height: 37px !important;
  line-height: 36px !important;
  font-weight: normal!important;
  padding: 0 12px !important;
  display: block;
  font-weight: 600 !important;
  border-radius: 12px;
}

#localize-active-lang, #localize-widget-langs a {
  font-size: 12px !important;
  max-width: 250px !important;
  min-width: 80px !important;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  box-sizing: border-box;
}

@media only screen and (max-width: 1600px) {
  #localize-widget.localize-right-top {
    right: 20% !important;
  }
}

@media only screen and (max-width: 1400px) {
  #localize-widget.localize-right-top {
    right: 20% !important;
  }
}

@media only screen and (max-width: 1200px) {
  #localize-widget.localize-right-top {
    right: 20% !important;
  }
}
@media only screen and (max-width: 900px) {
  #localize-widget.localize-right-top {
    right: 25% !important;
  }
  #localize-active-lang {
    font-size: 12px !important;
    height: 35px !important;
    line-height: 35px !important;
    font-weight: normal!important;
    padding: 0 5px !important;
    display: block;
    font-weight: 600 !important;
    border-radius: 12px;
  }
  #localize-active-lang, #localize-widget-langs a {
    font-size: 12px !important;
    max-width: 250px !important;
    min-width: 80px !important;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 600px) {
  #localize-widget.localize-right-top {
    right: 25% !important;
  }
}

@media only screen and (max-width: 480px) {
  #localize-widget.localize-right-top {
    display: none !important;
  }
}







html{
  font-size: 16px;
}
*{
  box-sizing: border-box;
}
:root{
  --brand-accent:rgba(116, 192, 68,1);
  --accent-shade:rgba(0, 154, 78,0.1);
  --accent-shade-d:rgba(0, 154, 78,0.2);
  --accent-shade-light:rgba(116, 192, 68,0.05);
  --primary-color:rgb(0, 154, 78);
  --primary-color-d:rgba(0, 154, 78,0.9);
  --stepper-color:rgba(0,0,0,0.5);
  --top-bar-border:var(--accent-shade);
  --shadow-color:#eeeeee;
  --contrast5:#f9f9f9;
  --border-medium:#e0e0e0;
  --border-dark:#c0c0c0;
  --border-light:#e6e6e6;
  --background-secondary:#f6f6f6;
  --background-primary:#fff;
  --font-regular:1rem;
  --font-medium:0.88rem;
  --font-small:0.82rem;
  --font-semilarge:1.1rem;
}
.row-hover tr{
  cursor: pointer;
}
.row-hover tr:hover{
  background-color: rgba(0,0,0,0.05);
}
.pointer{
  cursor:pointer
}
.hover-opacity{
  transition: 0.2s;
}
.hover-opacity:hover{
  opacity:0.7
}
.opacity40{
  opacity:0.4
}
.opacity50{
  opacity:0.5
}
.opacity60{
  opacity:0.6
}
.img-cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text-center{
  text-align: center;
}
.text-grey{
  color:#848484
}
.text-error{
  color:#D13135
}
.text-accent{
  color:rgb(0, 154, 78) !important;
  color:var(--primary-color) !important;
}
.underline{
  text-decoration: underline;
}
.font-regular{
  font-size: 1rem;
  font-size: var(--font-regular);
}
.font-medium{
  font-size: 0.88rem;
  font-size: var(--font-medium);
}
.font-small{
  font-size: 0.82rem;
  font-size: var(--font-small);
}
.small-icon{
 font-size: 15px;
}
.bold{
  font-weight: 700 !important;
}
.semi-bold{
  font-weight: 500 !important;
}
.capitalize{
  text-transform: capitalize;
}
.flex{
  display: flex;
}
.wrap {
    flex-wrap: wrap;
}
.no-margin{
  margin:0
}
.padding-regular {
    padding: 10px;
}
.padding-top{
  padding-top:10px;
}
.padding-left{
  padding-left:10px;
}
.padding-left--small{
  padding-left:5px;
}
.padding-bottom--small{
  padding-bottom:3px;
}
.padding-bottom{
  padding-bottom:10px;
}
.padding-bottom--l{
  padding-bottom:16px;
}
.padding-right{
  padding-right:10px;
}
.margin-top{
  margin-top:10px;
}
.margin-left{
  margin-left:10px;
}
.margin-bottom{
  margin-bottom:10px;
}
.margin-right{
  margin-right:10px;
}
.fill{
  flex:1 1;
}
.col-flex{
  flex-direction: column;
}
.space-between{
  justify-content: space-between;
}
.main-center{
  justify-content: center;
}
.cross-center{
  align-items: center;
}
.cross-baseline{
  align-items: baseline;
}
.main-end{
  justify-content: flex-end;
}
.cross-start{
  align-items: flex-start;
}
.cross-end{
  align-items: flex-end;
}
.position-relative{
  position: relative;
}

.basic-details-datepicker-input:hover,.basic-details-datepicker-input:focus{
  background-color: transparent;
}
.MuiInput-underline:before,.MuiInput-underline:after{
  display:none
}
.MuiInput-underline{
  cursor: pointer;
}
.carousel-prev,.carousel-next{
  width:50px !important;
  background-color: rgba(255,255,255,0.4) !important;
  border-radius: 6px !important;
}
.carousel-prev:hover,.carousel-next:hover{
  background-color: rgba(255,255,255,0.7) !important;
}
.carousel-prev:before,.carousel-next:before{
  height:30px !important;
  width:30px !important;
  opacity:1 !important;
}
.carousel-row div{
  outline: none !important;
}
.carousel-next:before{
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='768' height='768' viewBox='0 0 768 768'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M736 384c0-97.184-39.424-185.248-103.104-248.896s-151.712-103.104-248.896-103.104-185.248 39.424-248.896 103.104-103.104 151.712-103.104 248.896 39.424 185.248 103.104 248.896 151.712 103.104 248.896 103.104 185.248-39.424 248.896-103.104 103.104-151.712 103.104-248.896zM672 384c0 79.552-32.192 151.488-84.352 203.648s-124.096 84.352-203.648 84.352-151.488-32.192-203.648-84.352-84.352-124.096-84.352-203.648 32.192-151.488 84.352-203.648 124.096-84.352 203.648-84.352 151.488 32.192 203.648 84.352 84.352 124.096 84.352 203.648zM256 416h178.752l-73.376 73.376c-12.512 12.512-12.512 32.768 0 45.248s32.768 12.512 45.248 0l128-128c2.944-2.944 5.312-6.464 6.944-10.368 3.232-7.84 3.232-16.672 0-24.512-1.568-3.776-3.872-7.296-6.944-10.368l-128-128c-12.512-12.512-32.768-12.512-45.248 0s-12.512 32.768 0 45.248l73.376 73.376h-178.752c-17.664 0-32 14.336-32 32s14.336 32 32 32z'%3E%3C/path%3E%3C/svg%3E") !important;
}
.carousel-prev:before{
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='768' height='768' viewBox='0 0 768 768'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M736 384c0-97.184-39.424-185.248-103.104-248.896s-151.712-103.104-248.896-103.104-185.248 39.424-248.896 103.104-103.104 151.712-103.104 248.896 39.424 185.248 103.104 248.896 151.712 103.104 248.896 103.104 185.248-39.424 248.896-103.104 103.104-151.712 103.104-248.896zM672 384c0 79.552-32.192 151.488-84.352 203.648s-124.096 84.352-203.648 84.352-151.488-32.192-203.648-84.352-84.352-124.096-84.352-203.648 32.192-151.488 84.352-203.648 124.096-84.352 203.648-84.352 151.488 32.192 203.648 84.352 84.352 124.096 84.352 203.648zM512 352h-178.752l73.376-73.376c12.512-12.512 12.512-32.768 0-45.248s-32.768-12.512-45.248 0l-128 128c-3.072 3.072-5.376 6.592-6.944 10.368-1.632 3.904-2.432 8.096-2.432 12.256 0 8.192 3.136 16.384 9.376 22.624l128 128c12.512 12.512 32.768 12.512 45.248 0s12.512-32.768 0-45.248l-73.376-73.376h178.752c17.664 0 32-14.336 32-32s-14.336-32-32-32z'%3E%3C/path%3E%3C/svg%3E") !important;
}


/* flatpickr */

.flatpickr-day.selected{
  background-color: rgb(0, 154, 78) !important;
  background-color: var(--primary-color) !important;
  border-color: rgb(0, 154, 78) !important;
  border-color: var(--primary-color) !important;
}
.flatpickr-months .flatpickr-month,.flatpickr-monthDropdown-months{
  background-color: rgb(0, 154, 78) !important;
  background-color: var(--primary-color) !important;
}
.flatpickr-weekdays{
  background-color: rgb(0, 154, 78) !important;
  background-color: var(--primary-color) !important;
}
span.flatpickr-weekday{
  background: transparent !important;
  color:#fff !important;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month{
  padding:7px 10px !important
}
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover{
  color:#fff !important;
  fill:#fff !important;
  opacity: 0.8;
}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg{
  color:#fff !important;
  fill:#fff !important;
}
.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before {
  border-bottom-color:rgb(0, 154, 78) !important;
  border-bottom-color:var(--primary-color) !important;
}
.flatpickr-monthDropdown-months{
  -webkit-appearance: menulist-textfield !important;
}
  .PhoneInputCountry{
    /* border:1px solid #e2e2e2 !important; */
    border-radius: 8px !important;
    padding:0 10px 0 15px !important;
    font-size: 0.88rem !important;
    font-size: var(--font-medium) !important;
    background-color: #f0f0f0 !important;
  }
  .PhoneInputInput{
    padding:14px !important;
    border:0 !important;
    border-radius: 8px !important;
    font-size: 0.88rem !important;
    font-size: var(--font-medium) !important;
  }
  .PhoneInputInput:hover{
    border-color: rgba(0, 0, 0, 0.87) !important;
  }
  .PhoneInputInput:focus{
    border:0 !important;
    outline: none !important;
  }
  .PhoneInputInputError .PhoneInputInput{
    border-color: #D13135 !important;
  }
.flatpickr-container{width:100%;display:flex}.flatpickr-container .flatpickr-wrapper{margin-right:0px !important}.flatpickr-container .flatpickr-input{min-height:38px;font-size:14px;width:100%;min-width:162px;border:1px solid #ccc;border-radius:4px;padding-left:14px;outline:none}.flatpickr-container .flatpickr-input:focus{border:2px solid #ffc700}.flatpickr-container .flatpickr-wrapper{width:100%;display:block !important;min-width:150px;margin-right:5px !important}.flatpickr-container img.datePickerClearIcon{left:-30px;top:12px;position:relative;cursor:pointer;width:12px}.fleet-calendar-container .fleet-calendar{cursor:pointer;width:56px;color:#fff;height:42px;background-color:#fff;border-radius:10px;border:1px solid #ccc;outline:none;padding:12px 14px 0px}

.BaseDetails_searchInfo__2nskp{
  position: fixed;
  top:0;
  left:0;
  right:0;
  background-color: var(--primary-color);
  box-shadow: 0 5px 10px 1px #e2e2e2;
  z-index: 1100;
  padding:10px 20px
}
.BaseDetails_searchInfo__2nskp p{
  display: flex;
  align-items: center;
  margin:0;
  padding: 3px 0;
  color:#fff
}
.BaseDetails_searchInfo__2nskp p span{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
}
.BaseDetails_rangeLine__2jqE5{
  height:2px;
  border-radius: 1px;
  width:15px;
  min-width: 15px;
  background-color: #ffffff;
  margin:0 10px;
}

.BaseDetails_searchBox__8WOaX{
  background-color: var(--background-primary);
  border-bottom:1px solid var(--border-medium);
  display: flex;
  top:90px;
  z-index: 900;
  display: flex;
  align-items: center;
  transition: 0.2s;
}
@media screen and (max-width: 960px){
  .BaseDetails_searchBox__8WOaX{
    border:0;
  }
  .BaseDetails_searcContainer__2NvEf{
    margin-top: 0px;
  }
}

.BaseDetails_searchInputWrap__3nPsQ{
  display: flex;
  flex:1 1;
  margin:0 -20px;
}
.BaseDetails_searchQueryGroup__2r0bJ{
  display: flex;
  margin-right:15px;
}
.BaseDetails_searchQueryGroup__2r0bJ:last-child{
  margin:0;
}
.BaseDetails_searchParam__2vFhQ{
  cursor: pointer;
  position: relative;
  height: 64px;
  margin:5px 2px;
}

.BaseDetails_searchParam__2vFhQ.BaseDetails_dateTime__h9zJy,.BaseDetails_searchParam__2vFhQ.BaseDetails_rentalType__3sBd-{
    min-width: 0;
    min-width: initial;
    width:136px;
    flex:none;
    justify-content: flex-end;
    overflow:visible;
    overflow:initial
}
.BaseDetails_dropInputCheck__1lCPr{
  position: absolute;
  top:0;
  left:0;
  width:40px;
  bottom:0;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  padding-top:1px;
  z-index: 2;
}
.BaseDetails_dropInputCheckBox__VSXDj{
  color:var(--primary-color);
  font-size: 20px;
}
.BaseDetails_dropInputCheckBox__VSXDj:checked{
  color:var(--primary-color)
}
.BaseDetails_searchParamDisplay__3vBWW{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  flex:1 1;
  padding:0px 15px;
  padding-right: 10px;
  background-color: var(--accent-shade);
  border-radius: 10px;
  height: 64px;
  z-index: 1;
  transition: 0.2s;
  white-space: nowrap;
}
.BaseDetails_searchParamDisplay__3vBWW:hover{
  background-color:var(--accent-shade-d);
}
.BaseDetails_searchParamDisplay__3vBWW.BaseDetails_disabled___Obab{
  color:#c5c5c5
}
.BaseDetails_searchParamDisplay__3vBWW.BaseDetails_disabled___Obab:hover{
  background-color: var(--accent-shade);
  cursor: not-allowed;
}
.BaseDetails_searchParamDisplay__3vBWW p{
  margin:0;
  padding-bottom:6px;
  white-space: nowrap;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: var(--font-small);
  color: var(--primary-color);
}
.BaseDetails_searchParam__2vFhQ:last-of-type{
  border:0
}
.BaseDetails_searchParamDisplay__3vBWW div{
  font-size: var(--font-medium);
}
.BaseDetails_transparentInput__1p9Jj{ 
  border:0;
  outline: none;
  cursor: pointer;
  width:136px;
  opacity: 0;
}
.BaseDetails_searchButtonWrap__Ce2OF{
  width:100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  padding:5px;
}
.BaseDetails_searchButton__H5jl7{
  background-color: var(--primary-color);
  border-radius: 10px;
  width:100%;
  height:100%;
  min-height: 50px;
  border:0;
  cursor: pointer;
  outline:none;
  transition:0.2s;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
.BaseDetails_searchButton__H5jl7:active{
  opacity:0.8
}
.BaseDetails_searchButton__H5jl7:disabled{
  background-color: #d6d6d6;
  cursor: not-allowed;
}


.BaseDetails_searchResult__2FHnU{
  border-radius: 20px;
  border-top-left-radius:30px;
  border-top-right-radius:30px;
  box-shadow: 0 0 20px 2px var(--shadow-color);
  padding:40px 0;
  padding-top:90px;
  z-index: 1;
  min-height:700px;
  display: flex;
  margin:0 -20px; 
  margin-top:-50px;
}
.BaseDetails_seachFilter__1yt3-{
  width:250px;
  border-right:1px solid var(--shadow-color);
  display: flex;
  flex-direction: column;
}
.BaseDetails_seachFilter__1yt3- h3{
  margin:0;
  padding-left:30px;
}
.BaseDetails_filterCriteria__1PytR{
  padding:0 20px 0 30px;
  margin-top:20px;
}
.BaseDetails_filterCriteria__1PytR h5{
  margin:0;
  margin-bottom: 10px;
}
.BaseDetails_filterCriteriaItem__y5LNy{
  display: flex;
  align-items: center;
}
.BaseDetails_filterCriteriaItem__y5LNy p{
  padding-left: 10px;
  font-size: var(--font-medium);
  margin:8px 0;
}
.BaseDetails_blueTextButton__3aDTq{
  font-size: var(--font-small);
  color:var(--primary-color);
  font-weight:700;
  cursor: pointer;
  margin:5px 0;
}
.BaseDetails_backToSelectionScreen__hm0C5 {
  position: absolute;
  left: -5rem;
  top: 15px;
  transition: all 500ms ease-in-out;
}
@media(max-width: 1400px){
  .BaseDetails_backToSelectionScreen__hm0C5{
    display:none;
  }
}
.SummaryPanel_item__2Og5q{
  background-color: #f9f9f9;
  border-radius: 6px;
  padding:6px 6px;
  margin-bottom: 5px;
}

:root {
  --space: 150px;
  --space-xs: calc(var(--space) / 3);
  --space-sm: calc(var(--space) / 2);
  --space-md: calc(var(--space) * 2);
}

.SummaryPanel_sidebar__1-Xrp {
  --offset: var(--space);
  position: -webkit-sticky;
  position: sticky;
  top: var(--offset);
  max-height: calc(100vh - var(--offset) * 2);
}

@media screen and (max-width: 960px){
  .SummaryPanel_sidebar__1-Xrp{
    max-height: 650px;
  }
}


.CustomerView_container__1Uzqb{

  border-radius: 10px;
  padding:80px 40px;
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 100px;
  min-height: 100vh;
}


.CustomerView_avatar__137Uv{
 height: 70px;
 width:70px;
 border-radius: 16px;
 overflow: hidden;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #e2e2e2; 
}

.CustomerView_box__3Xlf7{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border:1px dashed #e5e5e5;
  border-radius: 10px;
  padding:15px;
}

.CustomerView_cardLeft__Kc2fG{
  padding:40px;
  padding-right:30px;
}
.CustomerView_cardRight__2VO0E{
  padding:40px;
}
.CustomerView_validation__1HpfQ{
  padding:8px 14px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  transition: 0.2s;
}
.CustomerView_inputError__39TxU{
  color:#D13135;
  padding:5px 15px 5px 15px;
}

.CustomerView_bookingQuestion__3CjDn{
  padding:8px 14px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
}

.CustomerView_validation__1HpfQ.CustomerView_error__2brgc,.CustomerView_bookingQuestion__3CjDn.CustomerView_error__2brgc{
  box-shadow:0 0 1px 1px rgba(209, 49, 53,0.8);
}
.CustomerView_rowFlex__2QJDH{
  display: flex;
  align-items: center;
}
.CustomerView_yesnoWrap__1Rc_Y,.CustomerView_dropdownWrap__XAC81{
  width:200px;
  display: flex;
  margin:5px 0;
}
.CustomerView_dropdownWrap__XAC81{
  background-color: #e8e8e8;
  border-radius: 8px;
}
.CustomerView_yesnoButton__LS0aP{
  display: flex;
  flex:1 1;
  background-color: #e2e2e2;
  font-size: 14px;
  color:#000000;
  justify-content: center;
  align-items: center;
  height:34px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}
.CustomerView_yesnoButton__LS0aP.CustomerView_left__b5YDX{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 4px;
}
.CustomerView_yesnoButton__LS0aP.CustomerView_right__3yuYI{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.CustomerView_yesnoButton__LS0aP.CustomerView_active__31MF9{
  background-color: var(--primary-color);
  color:#fff
}
.CustomerView_bottomBar__1-LFs{
    height:60px;
    box-shadow: 0 0 20px 1px rgba(0,0,0,0.06);
    padding:7px 20px;
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    flex:1 1;
    z-index: 1001;
}
.CustomerView_bottomBar__1-LFs.CustomerView_mobile__1Hi-3{
  padding:7px 20px;
}

.CustomerView_nextStepButton__qIGvC,.CustomerView_prevStepButton__z3ZGn{
    height:46px;
    width:150px;
    background-color: var(--primary-color);
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#fff;
    border:0;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
     margin-left: 20px;
}
.CustomerView_prevStepButton__z3ZGn{
    width:90px;
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    justify-content: center;
    margin-left: 0;
}
.CustomerView_prevStepButton__z3ZGn:hover{
    background-color: #fff;
}
.CustomerView_nextStepButton__qIGvC.CustomerView_mobile__1Hi-3{
  width: auto;
  flex:1 1;
  margin-left: 10px;
}
.CustomerView_nextStepButton__qIGvC:hover{
    background-color: var(--primary-color-d);
}
.CustomerView_nextStepButton__qIGvC:disabled{
    background-color: rgba(0,0,0,0.15);
    color:#fff;
    cursor:not-allowed
}
.CustomerView_footerUrl__1m5X5{
  text-decoration: none;
  float: right;
  padding:10px 0;
  font-size: var(--font-medium);
  display: inline;
}
.CustomerView_footerUrl__1m5X5:hover{
  text-shadow:0 0 0px #000;
  text-decoration: underline;
}
.CustomerView_footerUrl__1m5X5 div{
  color:#000;
  text-shadow:0 0 0px var(--primary-color);
  display: inline-flex;
  align-items: flex-end;
}

@media screen and (max-width: 600px){
  .CustomerView_container__1Uzqb{
    padding:80px 20px;
    padding-bottom: 160px;
  }
  .CustomerView_yesnoWrap__1Rc_Y,.CustomerView_dropdownWrap__XAC81{
    width: 150px;
    display: flex;
    margin:5px 0;
  }
  .CustomerView_footerUrl__1m5X5{
    vertical-align: bottom;
  }
}
.location-search{width:100%}.location-search .address-picker-form{width:100%}.location-search .autocomplete-dropdown-container{position:absolute;max-width:372px;z-index:9999;width:100%;word-break:break-all;font-size:14px;box-shadow:0px 1px 8px rgba(0,0,0,.2),0px 3px 3px rgba(0,0,0,.12),0px 3px 4px rgba(0,0,0,.14);border-radius:4px;margin-top:2px}.location-search .autocomplete-dropdown-container .suggestions{padding:8px 16px}.location-search .autocomplete-dropdown-container .suggestions:hover{background-color:#6b6b6b !important;color:#fff}.popover-address .MuiPopover-paper{width:28.8%;margin-left:-8rem !important;padding:27px 16px}
.NewBooking_appBody__12fu6 {
  background-color: #f1f3f4;
  flex: 1 1;
  height: calc(100vh - 239px);
}
.NewBooking_actionField__1oqe_ {
  background-color: #f1f3f4;
  min-height: 100vh;
  padding-top: 25px;
}
.NewBooking_bottomBar__1lnOX {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.06);
  padding: 7px 60px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1 1;
  z-index: 900;
}
.NewBooking_bottomBar__1lnOX.NewBooking_mobile__3DqCN {
  padding: 7px 20px;
  padding-left: 10px;
}
.NewBooking_floatingInfo__3lvOz {
  position: absolute;
  bottom: 60px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  box-shadow: 0 -4px 6px 1px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #000000;
  transition: 0.2s;
  overflow: hidden;
  /* border-radius: 10px; */
}
.NewBooking_floatingInfo__3lvOz .NewBooking_header__2GAVQ {
  display: flex;
  color: #000000;
  padding: 8px 20px;
  justify-content: space-between;
  transition: 0.2s;
  background-color: #ffffff;
}
.NewBooking_floatingInfo__3lvOz.NewBooking_open__3oWx4 .NewBooking_header__2GAVQ {
  background-color: #f5f5f5;
  padding: 16px 20px;
}
.NewBooking_floatingInfo__3lvOz .NewBooking_icon__1NFKQ {
  transition: 0.2s;
  margin-left: 5px;
}
.NewBooking_floatingInfo__3lvOz .NewBooking_icon__1NFKQ.NewBooking_down__28sXJ {
  transform: rotate(180deg);
}
.NewBooking_floatingInfo__3lvOz .NewBooking_info__13RMv {
  height: 250px;
  padding: 20px;
  overflow-y: scroll;
}
.NewBooking_selectionCount__2pQkP {
  font-size: var(--font-medium);
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.NewBooking_selectionCount__2pQkP span {
  padding-right: 5px;
}
.NewBooking_nextStepButton__3MjDi,
.NewBooking_prevStepButton__1jt9U {
  height: 46px;
  width: 150px;
  background-color: var(--primary-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  border: 0;
  font-size: var(--font-regualar);
  font-weight: 700;
  transition: 0.2s;
  outline: none;
  margin-left: 20px;
}
.NewBooking_prevStepButton__1jt9U {
  width: 90px;
  background-color: var(--accent-shade);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  margin-left: 10px;
  justify-content: center;
}
.NewBooking_prevStepButton__1jt9U:hover {
  background-color: #fff;
}
.NewBooking_nextStepButton__3MjDi.NewBooking_pc__XyJjR {
  width: auto;
  flex: 1 1;
  margin-left: 10px;
}
.NewBooking_nextStepButton__3MjDi.NewBooking_mobile__3DqCN {
  margin-left: 10px;
}
.NewBooking_nextStepButton__3MjDi:hover {
  background-color: var(--primary-color-d);
}
.NewBooking_nextStepButton__3MjDi:disabled {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
  cursor: not-allowed;
}

.NewBooking_topBarWrapper__JLgTo {
  height: 100%;
  display: flex;
  flex: 1 1;
  align-self: stretch;
  align-items: center;
}

.NewBooking_barTextWithUnderline__1Vxh5 {
  font-size: var(--font-regualar);
  position: relative;
  align-items: center;
  display: flex;
  margin-left: 20px;
  font-weight: 700;
}
.NewBooking_footer__2652J {
  padding: 20px 0 20px 0;
  background-color: #f7f7f7;
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
}
.NewBooking_footerUrl__dilOz {
  text-decoration: none;
  margin-left: 20px;
  font-size: var(--font-medium);
}
.NewBooking_footerUrl__dilOz:first-of-type {
  margin-left: 0px;
}
.NewBooking_footerUrl__dilOz:hover {
  text-shadow: 0 0 0px #000;
  border-bottom: 1px solid #000;
}
.NewBooking_footerUrl__dilOz div {
  color: #000;
  text-shadow: 0 0 0px var(--primary-color);
  display: flex;
  align-items: flex-end;
}

/* booking type selection style */
.NewBooking_bookingTypeContainer__ENl8e {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NewBooking_flexRow__3prTE {
  display: flex;
  margin: 0 0 32px;
  border-radius: 18px;
  border: 4px solid #49a6ba;
  background-color: #49a6ba;
  height: 78px;
}
.NewBooking_boxMainCon__1-JRq {
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 14px;
  padding: 40px 46px 30px;
}
.NewBooking_productTabLt__3GobO {
  padding: 3px 5px 2px;
  border: 4px solid #55c2da;
  border-radius: 12px;
  display: flex;
  flex-wrap: nowrap;
  background-color: #55c2da;
  min-width: 500px;
  max-width: 600px;
  min-height: 55px;
}
.NewBooking_bookNowButtonBox__3jivk {
  width: 100%;
  display: flex;
  justify-content: center;
}
.NewBooking_btnBookNow__1oSMY {
  font-size: 18px !important;
  padding: 16px 40px !important;
  border-radius: 14px !important;
  background-image: linear-gradient(
    92.88deg,
    #455eb5 9.16%,
    #5643cc 43.89%,
    #673fd7 64.72%
  );
  border-radius: 999px;
  box-shadow: #5e5df0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-word;
  border: 0;
  min-width: 300px;
}
.NewBooking_btnBookNow__1oSMY:hover {
  border-radius: 14px !important;
}
.NewBooking_productSubHeading__2CV2T {
  font-size: 16;
  text-transform: none;
  padding-top: 5px;
}
.NewBooking_productHeading__1PcRX {
  font-size: 20px;
  font-weight: 600;
}
.NewBooking_productSubHeading__2CV2T {
  font-size: 14px;
}
@media screen and (max-width: 960px) {
  .NewBooking_center__1Alx4 {
    justify-content: center !important;
    text-align: center;
  }
  .NewBooking_productTabLt__3GobO {
    padding: 3px 5px 2px;
    border: 4px solid #55c2da;
    border-radius: 12px;
    display: flex;
    background-color: #55c2da;
    min-width: 500px;
    max-width: 800px;
    min-height: 80px;
  }
  .NewBooking_boxMainCon__1-JRq {
    padding: 25px 20px 25px;
  }
  .NewBooking_productHeading__1PcRX {
    font-size: 16px;
    font-weight: 600;
  }
  .NewBooking_productSubHeading__2CV2T {
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .NewBooking_bookingTypeContainer__ENl8e {
    height: auto;
    margin-top: 80px;
    padding: 20px;
  }
  .NewBooking_productTabLt__3GobO {
    min-width: 440px;
    max-width: 550px;
    min-height: 55px;
  }
  .NewBooking_productHeading__1PcRX {
    font-size: 14px !important;
    font-weight: 600;
  }
  .NewBooking_productSubHeading__2CV2T {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 480px) {
  .NewBooking_bookingTypeContainer__ENl8e {
    height: auto;
    margin-top: 80px;
    padding: 20px;
  }
  .NewBooking_boxMainCon__1-JRq {
    padding: 25px 20px 25px;
  }
  .NewBooking_productTabLt__3GobO {
    min-width: 200px !important;
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    min-height: 55px;
  }
  .NewBooking_appBody__12fu6 {
    margin-top: 0;
  }
  .NewBooking_productHeading__1PcRX {
    font-size: 12px !important;
    font-weight: 600;
  }
  .NewBooking_productSubHeading__2CV2T {
    font-size: 10px !important;
  }
}
.NewBooking_backButtonMB__3rAfT{
  display: none;
  margin-top: 1px;
}
@media (max-width: 1400px){
  .NewBooking_backButtonMB__3rAfT{
    display: block;
  }
}
@media (max-width: 960px){
  .NewBooking_backButtonMB__3rAfT{
    display: block;
    margin-top:70px;
  }
}

.VehicleSelection_slickySlider__1J_Vl {
  width: 100%;
}

.VehicleSelection_slickySlider__1J_Vl .VehicleSelection_slideImage__3k8Rp {
  width: 100%;
}
.VehicleSelection_searchResult__2V0GN {
  z-index: 1;
  min-height: 700px;
  max-width: 1280px;
  margin: auto;
  padding: 0 20px 100px 20px;
  position: relative;
}

.VehicleSelection_vehicleGroupcard__1NeI3 {
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  transition: 0.3s;
  width: 100%;
}

.VehicleSelection_groupContentWrap__1zKlK{
  padding: 0;
}
@media(max-width: 959px){
  .VehicleSelection_vehicleGroupcard__1NeI3{
    padding: 0;
    margin-bottom:0;
    flex-direction: column;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.08);
  }
  .VehicleSelection_groupContentWrap__1zKlK{
    padding: 10px;
  }
}

.VehicleSelection_leftBorder__2Hapf {
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  width: 4px;
  background-color: #2a9cb7;
  transition: 0.3s;
}
.VehicleSelection_groupIconOuterWrap__129JQ{
  width: 120px;
  padding: 0;
}
@media(max-width:959px){
  .VehicleSelection_groupIconOuterWrap__129JQ{
    width: 100%;
    padding: 10px;
  }
}
.VehicleSelection_groupIconWrap__1_LW9,
.VehicleSelection_vehicleIconWrap__izIqF {
  height: 120px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #a0a0a0;
  display: flex;
  align-items: center;
}
.VehicleSelection_groupIconWrap__1_LW9{
  position: relative;
  overflow: hidden;
}
.VehicleSelection_vehicleIconWrap__izIqF{
  width: 120px;
}
.VehicleSelection_groupIconWrap__1_LW9 img,
.VehicleSelection_vehicleIconWrap__izIqF img {
  width: 100%;
  height:100%;
}

.VehicleSelection_groupIconWrap__1_LW9.VehicleSelection_mobile__3pdSt {
  margin: 0;
}

.VehicleSelection_groupInfoWrap__28d8R,
.VehicleSelection_vehicleInfoWrap__3pI-S {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 5px 0 5px 20px;
  justify-content: space-between;
}
.VehicleSelection_vehicleInfoOuterContainer__R5f6D{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:flex-start;
}
@media (max-width: 959px){
  .VehicleSelection_groupInfoWrap__28d8R{
    padding: 0;
  }
  .VehicleSelection_groupIconWrap__1_LW9{
    width:100%;
    height: 175px;
  }
  .VehicleSelection_vehicleInfoOuterContainer__R5f6D{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-end;
  }
}
.VehicleSelection_vehicleInfoContainer__1rf8D{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.VehicleSelection_vehicleInfoContainer__1rf8D h3{
  margin:0;
}

.VehicleSelection_vehicleFeatureWrap__7utjP {
  display: flex;
  flex-direction:row;
  padding-left: 15px;
  flex: 1 1;
  justify-content: flex-end;
  align-items:flex-start;
  flex-wrap: wrap;
}
@media (max-width: 1280px){
  .VehicleSelection_vehicleInfoContainer__1rf8D{
    margin-top: 10px;
  }
  .VehicleSelection_groupIconWrap__1_LW9{
    align-items: flex-end;
  }
}
@media (max-width: 959px){
  .VehicleSelection_vehicleInfoContainer__1rf8D{
    width:100%;
  }
  .VehicleSelection_vehicleFeatureWrap__7utjP{
    padding-left: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    grid-row-gap: 5px;
    row-gap: 5px;
    width:100%;
  }
}

.VehicleSelection_vehicleFeature__MVjNS {
  font-size: var(--font-small);
  margin-right: 25px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  font-weight: 800;
}

.VehicleSelection_vehicleFeature__MVjNS.VehicleSelection_mobile__3pdSt {
  margin: 0;
  margin-right: 10px;
  margin-top:5px;
  display: inline-block;
}

.VehicleSelection_vehicleFeature__MVjNS:last-child {
  margin-right: 15px;
}

@media (max-width: 959px){
  .VehicleSelection_vehicleFeature__MVjNS:last-child{
    margin-right: 0;
  }
}
.VehicleSelection_vehiclePrice__12ykZ {
  margin-right: 15px;
  display: flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
@media (max-width: 959px){
  .VehicleSelection_vehiclePrice__12ykZ{
    margin-right: 0;
    flex-direction: column;
  }
  
}
.VehicleSelection_vehiclePrice__12ykZ.VehicleSelection_mobile__3pdSt {
  min-width: 0;
  min-width: initial;
  justify-content: initial;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}
.VehicleSelection_infoIcon__18SbU{
  display:inline;
}
.VehicleSelection_strikedPrice__MBVEi {
  text-decoration: line-through;
  color: #848484;
  font-size: var(--font-medium);
  padding-right: 10px;
}

.VehicleSelection_currentPrice__1e8z5 {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.15rem;
  line-height: 0.9;
  display: block;
}

.VehicleSelection_showMoreButton__2rW-4 {
  margin-left: -4px;
  padding: 6px 6px;
  padding-right: 2px;
  display: flex;
  border-radius: 6px;
  font-size: var(--font-small);
  background-color: rgb(248, 249, 251);
  color: var(--brand-accent);
  font-weight: 700;
  cursor: pointer;
  -webkit-user-select: none;
}

.VehicleSelection_vehiclesWrap__36AZe {
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 40px;
  border-top: 1px solid #f6f6f6;
}

.VehicleSelection_vehicleCard__3nY1Q {
  display: flex;
  padding: 5px 0;
}

.VehicleSelection_vehicleIconWrap__izIqF {
  width: 120px;
  height: 100px;
}

.VehicleSelection_vehicleInfoWrap__3pI-S {
  justify-content: space-around;
}

.VehicleSelection_vehicleSelectButtonWrap__e_As5 {
  display: flex;
  align-items: center;
}

.VehicleSelection_vehicleQuantitySelectionWrap__2zKvp{
  display:flex;
  flex-direction: row;
  justify-items: space-between;
  align-items: center;
  margin-top: 0
}
@media (max-width: 959px){
  .VehicleSelection_vehicleQuantitySelectionWrap__2zKvp{
    padding: 10px;
    background-color: #e1e1e1;
  }
}
@media (max-width: 700px) and (min-width: 600px){
  .VehicleSelection_vehicleQuantitySelectionWrap__2zKvp{
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    margin-top: 5px;
    grid-gap: 10px;
    gap: 10px;
  }
}
@media (max-width:350px){
  .VehicleSelection_vehicleQuantitySelectionWrap__2zKvp{
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    margin-top: 5px;
    grid-gap: 10px;
    gap: 10px;
  }
}
.VehicleSelection_buttonStyledCheck__2_ScW:before {
  content: "Select";
  -webkit-appearance: none;
  background-color: #2a9cb7;
  width: 158px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 8px;
  color: #fff;
  position: relative;
  margin-left: -40px;
  cursor: pointer;
}

.VehicleSelection_vehicleSelectCheck__G5Hj3:checked + .VehicleSelection_buttonStyledCheck__2_ScW:before {
  content: "Remove";
  background-color: #fddfdc;
  color: #e00101;
  font-weight: normal;
  font-weight: initial;
}

.VehicleSelection_seachFilter__lFHIf {
  width: 360px;
  display: flex;
  padding-right: 20px;
  flex-direction: column;
}

.VehicleSelection_filterView__1qwc5 {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex: 1 1;
  margin-bottom: 15px;
}

.VehicleSelection_vehicleResult__6AbF5 {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.VehicleSelection_filterCriteria__A0FQB {
  padding: 0 20px 0 30px;
  margin-top: 20px;
}

.VehicleSelection_filterCriteria__A0FQB h5 {
  margin: 0;
  margin-bottom: 10px;
}

.VehicleSelection_filterCriteriaItem__2QX9L {
  display: flex;
  align-items: center;
}

.VehicleSelection_filterCriteriaItem__2QX9L p {
  padding-left: 10px;
  font-size: var(--font-medium);
  margin: 8px 0;
}

.VehicleSelection_blueTextButton__17wyc {
  font-size: var(--font-small);
  color: var(--primary-color);
  font-weight: 700;
  cursor: pointer;
  margin: 5px 0;
}

.VehicleSelection_priceName__2btn6 {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
}

.VehicleSelection_sortAction__3qPnV {
  position: relative;
  z-index: 100;
}

.VehicleSelection_sortButton__2MDmk {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 3px 5px 3px 2px;
  transition: 0.2s;
  cursor: pointer;
}

.VehicleSelection_sortButton__2MDmk:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.VehicleSelection_sortOptions__1qW_u {
  background-color: #fff;
  padding: 8px 0px;
  position: absolute;
  top: 25px;
  right: 0;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  display: none;
  transition: 0.2s;
  overflow: hidden;
}

.VehicleSelection_sortOptions__1qW_u p {
  margin: 0;
  padding: 6px 20px;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

.VehicleSelection_sortOptions__1qW_u p:hover {
  background-color: #f0f0f0;
}

.VehicleSelection_sortAction__3qPnV:hover .VehicleSelection_sortOptions__1qW_u {
  display: block;
}

.VehicleSelection_disableRentals__1AC7e {
  opacity: 0.4;
  pointer-events: none;
}
.VehicleSelection_modalContent__1AfHR {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  max-height: 50%;
}

.VehicleSelection_sliderImageWrapper__2kbLH {
  text-align: center;
}

.VehicleSelection_subscriptionCarMain__2Zn94 {
  border-top-left-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px !important;
}

.VehicleSelection_filterBottomSheet__2mk70{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 60px;
  box-shadow: 0 1px 10px gray;
  width:100%;
  left:0;
  max-height: 0;
  border-radius: 10px 10px 0 0 ;
  z-index: 100;
  background-color: white;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}
.VehicleSelection_filterBottomSheet__2mk70.VehicleSelection_activeBottomSheet__CulOf{
  max-height: 500px;
}
.slickySlider {
  width: 100%;
}
.slickySlider .slick-arrow {
  z-index: 4;
}
.slickySlider .slick-arrow.slick-prev {
  left: 2rem;
}
.slickySlider .slick-arrow.slick-next {
  right: 2rem;
}
.slickySlider .slick-arrow.slick-prev::before {
  font-size: 2rem;
}
.slickySlider .slick-arrow.slick-next::before {
  font-size: 2rem;
}
.carauselContainer {
  width: 100%;
}
.imageContainer {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
}
.imageContainer .image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.FiltersView_bottomSheet__17TDf{
    height:50vh;
    width:100%;
}
.FiltersView_bottomSheetHeader__yyOxZ{
    padding: 1rem 0.75rem;
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.FiltersView_bottomSheetSelection__2YSa9{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    height: calc(100% - 3.563rem);
    border-top: rgb(188, 188, 188) solid 1px;
}
.FiltersView_bottomSheetFilterList__1f6un{
    width:150px;
    height:100%;
    overflow-y: auto;
}
.FiltersView_bottomSheetFilter__2Rz5j{
    padding: 1rem;
    border-bottom: 1px solid rgb(227, 227, 227);
    border-right: 1px solid rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    text-align: center;
}
.FiltersView_bottomSheetFilter__2Rz5j.FiltersView_active__2VNwK{
    background-color:rgb(227, 227, 227);
    color: var(--brand-accent);
}
.FiltersView_filterSelectionWrapper__21Cza{
    padding: 2rem;
    width:100%;
    height:100%;
    overflow-y: auto;
}
.FiltersView_filterSelection__2f_6e{
    display:flex;
    justify-content: flex-start;
    align-items:center;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
}
.FiltersView_singleFilter__2bj5J{
    padding: 0.45rem 0.35rem;
    min-width: 100px;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
    text-align: center;
    background-color: transparent;
    word-break: break-word;
}
.FiltersView_singleFilter__2bj5J.FiltersView_activeFilter__2w1cj{
    background-color: rgb(223, 222, 222);
}
.VehicleGallery_carouselModule__UGDQu{
  padding:50px 0;
  padding-bottom: 80px;
  background-color:#fff;
}
.VehicleGallery_sectionHeaderLine__q2H9q{
  width:30px;
  height:4px;
  border-radius: 2px;
  background-color: var(--primary-color);
}
.VehicleGallery_carauselContainer__280Ok{
  
}
.VehicleGallery_slider__3biEf{
  border-radius: 10px;
  overflow:hidden;
}
.VehicleGallery_imageGrid__2a9uL{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
}
.VehicleGallery_imageContainer__YIHag,.VehicleGallery_gridImage__3fT4j{
  width:auto;
  outline: none;
  padding:0 3px;
  position: relative;
  margin-bottom: 6px;
}

.VehicleGallery_imageContainer__YIHag:last-child{
  margin-bottom: 0;
}
.VehicleGallery_gridImage__3fT4j{
  display: flex;
  flex: 0 0 33.333333%;
  margin: 3px 0;
}
.VehicleGallery_image__2n1ti{
  height: auto;
  width:100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
}
.VehicleGallery_image__2n1ti img{
  height:100%;
  width:100%;
  object-fit: cover;
}
.VehicleGallery_arrowCircle__2NFOr{
  height:30px;
  width:30px;
  border:2px solid #e2e2e2;
  color:#848484;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items:center;
}
.PageModules_pageModule__2VuWl{
  min-height: calc(100vh - 240px);
  background-color: #fff;
}
.PageModules_moduleWrap__1gzRp{
  padding:0;
  text-align: center;
  background-color: #fff;
  /* border-bottom: 1px solid #e7e7e7; */
}

.PageModules_diffModule__3CTKU {
  padding: 0;
  background-color:#fff;
}

@media (min-width: 768px) {
  .PageModules_moduleWrap__1gzRp{
    padding:60px 0 20px 0;
    text-align: center;
    background-color: #fff;
    /* border-bottom: 1px solid #e7e7e7; */
  }
  
  .PageModules_diffModule__3CTKU {
    padding: 40px 15px 80px 30px;
    background-color:#fff;
  }
}
.TopBar_appContainer__1wir3{
  display:flex;
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  flex-direction: column;
  align-items: stretch;
}
.TopBar_topBar__umuVT{
  height:90px;
  background-color: var(--brand-accent);
  border-bottom:1px solid var(--top-bar-border);
  position: fixed;
  top:0;
  left:0;
  right:0;
  display: flex;
  z-index: 999;
  align-items: stretch;
  transition: 0.2s;
}

.TopBar_topBarWrapper__1usbD{
  height:100%;
  display: flex;
  flex:1 1;
  align-self: stretch;
  align-items: center;
}
.TopBar_topBarLogo__3lgiW{
  min-width:40px;
  height: 50%;
  display: flex;
  align-items: center;
}
.TopBar_topBarLogo__3lgiW img{
  height:auto;
  width:auto;
  max-height: 100%;
}
.TopBar_topBarLogo__3lgiW p{
  color:var(--brand-accent);
  font-size: 22px;
}

.TopBar_topBarTitle__3i55z {
  font-weight: 700;
  max-width: 85%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: calc(1.5em * 3);
  -webkit-line-clamp: 3;
}
@media (max-width: 450px){
  .TopBar_topBarTitle__3i55z{
    max-width: 100%;
  }
}
.TopBar_topBarButtonTitle__hhI0w{
  font-weight: 700 !important;
  white-space: nowrap;
}
@media (max-width: 360px){
  .TopBar_topBarButtonTitle__hhI0w{
    white-space: wrap;
    margin-left: 10px;
  }
}

.TopBar_barTextWithUnderline__Y7mz-{
  font-size: var(--font-regualar);
  position: relative;
  align-items: center;
  display: flex;
  margin-left: 20px;
  font-weight: 700;
}
.TopBar_footer__2tzPG{
  height: 60px;
  background-color: #f7f7f7;
  display: flex;
}
.TopBar_footerUrl__1edmq{
  text-decoration: none;
  margin-right: -20px;
  margin-left: 40px;
  font-size: var(--font-medium);
}
.TopBar_footerUrl__1edmq:hover{
  text-shadow:0 0 0px #000;
  border-bottom: 1px solid #000;
}
.TopBar_footerUrl__1edmq div{
  color:#000;
  text-shadow:0 0 0px var(--primary-color);
  display: flex;
  align-items: flex-end;
}
.TopBar_auth__19PSG{
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 0px;
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  color: #000;
  font-size: 13px;
  cursor: pointer;
}

.TopBar_iconWrap__eOWB5{
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.TopBar_authDialog___lgfv{
  padding: 40px;
}
@media (max-width: 400px){
  .TopBar_authDialog___lgfv{
    padding: 20px;
  }
}
.Auth_container__3z9Vz {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
}

.Auth_topbar__1RCv- {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: var(--theme-accent);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Auth_logo__ZPj-8 {
  height: 52px;
}

.Auth_body__i0oxS {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 60px;
  overflow-y: auto;
  background-color: #fff;
}

.Auth_card__31oov {
  max-width: 400px;
  margin: auto;
}

.Auth_card__31oov .Auth_top__3ORVA,
.Auth_card__31oov .Auth_bottom__2Wg1y {
  padding: 10px 30px 20px 30px;
  background-color: var(--accent-shade-light);
  border-radius: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Auth_card__31oov .Auth_top__3ORVA {
  display: flex;
  align-items: center;
}

.Auth_card__31oov .Auth_bottom__2Wg1y {
  padding-top: 26px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 4px;
}

.Auth_card__31oov p {
  font-size: 14px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.Auth_errorText__watKj {
  color: #c71717;
  float: right;
  margin-left: 10px;
  font-weight: normal;
  font-weight: initial;
}

.Auth_errorText__watKj.Auth_left__2Ye8Q {
  float: none;
  float: initial;
  margin: 4px 0;
  font-size: 14px;
}

.Auth_errorBox__2bdMS,
.Auth_infoBox__Ycsi8 {
  background: #ffe0e0;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  border: 1px solid #bf4848;
  color: #bf4848;
  font-size: 14px;
}

.Auth_infoBox__Ycsi8 {
  background-color: #eee4ca;
  border-color: #c7b294;
  color: #a36200;
}

.Auth_payAmoutButton__3Sdsw {
  padding: 14px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  transition: 0.2s;
  color: #000000;
  min-width: 300px;
}

.Auth_payAmoutButton__3Sdsw:hover {
  background-color: #f0f0f0;
}

.Auth_payAmoutButton__3Sdsw.Auth_full__1cZVj {
  background-color: var(--accent-shade);
}

.Auth_payAmoutButton__3Sdsw:hover,
.Auth_payAmoutButton__3Sdsw.Auth_full__1cZVj:hover {
  background-color: var(--accent-shade-d);
}

.Auth_valid__2Maw9{
  font: "Times New Roman";
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-small;
  color:green;
  margin-left:30px;
}
.Auth_valid__2Maw9:before {
  position: relative;
  left: -35px;
  content: "✔";
}

.Auth_invalid__2Knue { 
  font: "Times New Roman";
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-small;
  color:red;
  margin-left: 30px;
}
.Auth_invalid__2Knue:before {
  position: relative;
  left: -35px;
  content: "✖";
}

.Auth_bottomLinks__1ZATL{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.Auth_bottomLinks__1ZATL p{
  color: var(--theme-accent);
  margin: 0 0 10px 0;
  cursor: pointer;
}
@media (max-width: 400px) {
  .Auth_bottomLinks__1ZATL {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .Auth_bottomLinks__1ZATL p {
    margin: 0;
    margin-bottom: 10px;
  }
}
.Sidebar_column__3bNqI{
  width:200px;
  position: fixed;
  top:0;
  bottom: 0;
  left:0.0625rem;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  padding-top: 126px;
  z-index: 10;
}
.Sidebar_menuIcon__EqPTm{
  background-color: var(--primary-color);
  box-shadow: 0 0 3px gray;
  margin-right: 10px;
  border-radius: 25%;
}
@media only screen and (min-width: 1280px) {
  .Sidebar_column__3bNqI{
    left:calc(50% - 610px);
  }
}

.Sidebar_navItem__1Z9xn{
  display: flex;
  align-items: center;
  padding:14px 16px;
  border-bottom: 1px solid #eeeeee;
  font-size: 18px;
  cursor: pointer;
  border-left: 4px solid transparent;
  text-decoration: none;
  color: initial;
  color: #292929;
}

.Sidebar_navItem__1Z9xn.Sidebar_active__71qiq{
  border-left-color: var(--primary-color);
  color: var(--primary-color);
}

.Sidebar_draweHeader__1P0Nq{
  width: 0.75rem;
}

.Sidebar_navItem__1Z9xn span{
  font-weight: 500;
  margin-left: 8px;
  font-size: 14px;
}
body {
  font-family: "Albert Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}
.Subscription_subscription_pricing-overview__1a5gR {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
}
.Subscription_next_step_btn__1KWTy {
  background: #000 !important;
  color: #fff !important;
  padding: 10px !important;
}
.Subscription_next_step_btn__1KWTy:disabled {
  background-color: lightgrey !important;
}
.Subscription_next_step_btn__1KWTy:hover {
  background: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
}
.Subscription_vehicle_catalogue__2bEoT {
  background-color: transparent !important;
  position: relative;
}
.Subscription_card_media_container__DvA0U {
  padding: 20px;
  border: 1px solid #585858;
  min-height: 300px;
  height: 100%;
  display: flex
;
  align-items: center;
}
.Subscription_vehicle_catalogue_content__1Mttx {
  padding: 20px;
}
.Subscription_vehicle_catalogue_selected__S8xpa {
  position: absolute;
}
.Subscription_book_now_button__1lagf {
  width: 100% !important;
  background-color: #C1C1C1 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  border-radius: 0px !important;
}
.Subscription_book_now_button__1lagf:hover {
  background-color: #D9D9D9 !important;
}
.Subscription_plan_inclusion_list__nP7jM {
  padding-left: 20px;
  font-weight: 600;
  font-weight: 14px;
}
.Subscription_plan_inclusion_list__nP7jM li {
  font-size: 14px;
  padding: 5px;
}
/* dvla licence check styles */
.Subscription_licence_check_container__2RA7y {
  display: flex;
  align-items: center;
}
.Subscription_dvla_main_container__1o3Op {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 30px;
  flex-grow: 1;
}

.Subscription_included_plan_services__3W8Yx {
  border-radius: 10px;
  min-height: 150px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 15px 15px;
  height: 100%;
  justify-content: space-between;
}
.Subscription_included_service_icon__3qP_B {
  width: 30px;
  height: 30px;
}

.Subscription_subscription_input_style__3Gtwg {
  border-radius: 0px !important;
  line-height: 1.3rem !important;
}

.SelectAddOns_addonField__3lFlD h3{
  text-align: center;
  margin-bottom: 20px;
  margin-top:0;
}
.SelectAddOns_addonContainer__2bFOp{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.SelectAddOns_customAddonCard__3WJim,.SelectAddOns_insuranceAddonCard__1gQdm{
  border-radius:10px;
  border:1px dashed transparent;
  min-height:280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:30px 15px 30px 15px;
  height: 100%;
}
.SelectAddOns_addonIcon__VZ_sM{
  width:30px;
  height:30px;
  margin-bottom:20px;
}
.SelectAddOns_addonIcon__VZ_sM img{
  width:100%;
  height:100%;
}
.SelectAddOns_addonInfo__ZmgGn{
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space:normal;
  white-space:initial;
  flex:1 1;
}
.SelectAddOns_addonInfo__ZmgGn p{
  font-size: var(--font-medium);
  font-weight: 700;
  margin:6px 0;
  text-align: center;
}
.SelectAddOns_addonInfo__ZmgGn span{
  text-align: center;
}
.SelectAddOns_addonRate__1pnrg{
  font-size: var(--font-semilarge);
  font-weight: 700;
  margin:15px 0;
  color:var(--primary-color);
}
.SelectAddOns_addCounter__3Vl-Q{
  position: relative;
}

.SelectAddOns_addonSelectButtonWrap__1LBXI{
  display: flex;
  align-items: center;
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
}
.SelectAddOns_insuranceSelectButtonWrap__1k6VH{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width:180px;
  height:34px;
}
.SelectAddOns_buttonStyledCheck__1lxfg:before{
  content:'Select';
  -webkit-appearance: none;
  background-color: #2A9CB7;
  width:180px;
  height:34px;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 8px;
  color:#fff;
  position: absolute;
  top:0;
  left:50%;
  margin-left:-90px;
  right:0;
  bottom:0;
  cursor:pointer;
}
.SelectAddOns_addonSelectCheck__3d811:checked + .SelectAddOns_buttonStyledCheck__1lxfg:before{
  content:'Remove';
  background-color: #fddfdc;
  color:#e00101;
  font-weight: normal;
  font-weight: initial;
}
.CustomerView_container__YLQ1V{

  border-radius: 10px;
  padding:80px 40px;
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 100px;
  min-height: 100vh;
}


.CustomerView_avatar__2zr1V{
 height: 70px;
 width:70px;
 border-radius: 16px;
 overflow: hidden;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #e2e2e2; 
}

.CustomerView_box__10D77{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border:1px dashed #e5e5e5;
  border-radius: 10px;
  padding:15px;
}

.CustomerView_cardLeft__2W6wx{
  padding:40px;
  padding-right:30px;
}
.CustomerView_cardRight__CC23c{
  padding:40px;
}
.CustomerView_validation__JXB5U{
  padding:8px 14px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  transition: 0.2s;
}
.CustomerView_inputError__2f2bc{
  color:#D13135;
  padding:5px 15px 5px 15px;
}

.CustomerView_bookingQuestion__3DSV5{
  padding:8px 14px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
}

.CustomerView_validation__JXB5U.CustomerView_error__4EupN,.CustomerView_bookingQuestion__3DSV5.CustomerView_error__4EupN{
  box-shadow:0 0 1px 1px rgba(209, 49, 53,0.8);
}
.CustomerView_rowFlex__23Fu4{
  display: flex;
  align-items: center;
}
.CustomerView_yesnoWrap__11F-J,.CustomerView_dropdownWrap__1KoCF{
  width:200px;
  display: flex;
  margin:5px 0;
}
.CustomerView_dropdownWrap__1KoCF{
  background-color: #e8e8e8;
  border-radius: 8px;
}
.CustomerView_yesnoButton__33WLg{
  display: flex;
  flex:1 1;
  background-color: #e2e2e2;
  font-size: 14px;
  color:#000000;
  justify-content: center;
  align-items: center;
  height:34px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}
.CustomerView_yesnoButton__33WLg.CustomerView_left__3-u59{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 4px;
}
.CustomerView_yesnoButton__33WLg.CustomerView_right__2n5l8{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.CustomerView_yesnoButton__33WLg.CustomerView_active__2RMyL{
  background-color: var(--primary-color);
  color:#fff
}
.CustomerView_bottomBar__3JGuh{
    height:60px;
    box-shadow: 0 0 20px 1px rgba(0,0,0,0.06);
    padding:7px 20px;
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    flex:1 1;
    z-index: 1001;
}
.CustomerView_bottomBar__3JGuh.CustomerView_mobile__2-iYY{
  padding:7px 20px;
}

.CustomerView_nextStepButton__1iH3C,.CustomerView_prevStepButton__319cs{
    height:46px;
    width:150px;
    background-color: var(--primary-color);
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#fff;
    border:0;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
     margin-left: 20px;
}
.CustomerView_prevStepButton__319cs{
    width:90px;
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    justify-content: center;
    margin-left: 0;
}
.CustomerView_prevStepButton__319cs:hover{
    background-color: #fff;
}
.CustomerView_nextStepButton__1iH3C.CustomerView_mobile__2-iYY{
  width: auto;
  flex:1 1;
  margin-left: 10px;
}
.CustomerView_nextStepButton__1iH3C:hover{
    background-color: var(--primary-color-d);
}
.CustomerView_nextStepButton__1iH3C:disabled{
    background-color: rgba(0,0,0,0.15);
    color:#fff;
    cursor:not-allowed
}
.CustomerView_footerUrl__1zHxw{
  text-decoration: none;
  float: right;
  padding:10px 0;
  font-size: var(--font-medium);
  display: inline;
}
.CustomerView_footerUrl__1zHxw:hover{
  text-shadow:0 0 0px #000;
  text-decoration: underline;
}
.CustomerView_footerUrl__1zHxw div{
  color:#000;
  text-shadow:0 0 0px var(--primary-color);
  display: inline-flex;
  align-items: flex-end;
}

.CustomerView_next_step_btn__2yq48 {
  background: #000 !important;
  color: #fff !important;
  padding: 10px !important;
}
.CustomerView_next_step_btn__2yq48:hover {
  background: #fff !important;
  color: #000 !important;
  border: 0.5px solid #000;
  box-shadow: none !important;
}

@media screen and (max-width: 600px){
  .CustomerView_container__YLQ1V{
    padding:80px 20px;
    padding-bottom: 160px;
  }
  .CustomerView_yesnoWrap__11F-J,.CustomerView_dropdownWrap__1KoCF{
    width:100px;
    display: flex;
    margin:5px 0;
  }
  .CustomerView_footerUrl__1zHxw{
    vertical-align: bottom;
  }
}

.SelectAddOns_addonField__21ZGN h3{
  text-align: center;
  margin-bottom: 20px;
  margin-top:0;
}
.SelectAddOns_addonContainer__5KLOt{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.SelectAddOns_customAddonCard__oEo1Y,.SelectAddOns_insuranceAddonCard__3G8d3{
  border-radius:10px;
  border:1px dashed transparent;
  min-height:280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:30px 15px 30px 15px;
  height: 100%;
}
.SelectAddOns_addonIcon__aMuX3{
  width:30px;
  height:30px;
  margin-bottom:20px;
}
.SelectAddOns_addonIcon__aMuX3 img{
  width:100%;
  height:100%;
}
.SelectAddOns_addonInfo__ZL33F{
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space:normal;
  white-space:initial;
  flex:1 1;
}
.SelectAddOns_addonInfo__ZL33F p{
  font-size: var(--font-medium);
  font-weight: 700;
  margin:6px 0;
  text-align: center;
}
.SelectAddOns_addonInfo__ZL33F span{
  text-align: center;
}
.SelectAddOns_addonRate__9vtMC{
  font-size: var(--font-semilarge);
  font-weight: 700;
  margin:15px 0;
  color:var(--primary-color);
}
.SelectAddOns_addCounter__3stNB{
  position: relative;
}

.SelectAddOns_addonSelectButtonWrap__3q7l-{
  display: flex;
  align-items: center;
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
}
.SelectAddOns_insuranceSelectButtonWrap__20Adk{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width:180px;
  height:34px;
}
.SelectAddOns_buttonStyledCheck__3cS6d:before{
  content:'Select';
  -webkit-appearance: none;
  background-color: #2A9CB7;
  width:180px;
  height:34px;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 8px;
  color:#fff;
  position: absolute;
  top:0;
  left:50%;
  margin-left:-90px;
  right:0;
  bottom:0;
  cursor:pointer;
}
.SelectAddOns_addonSelectCheck__2R4gW:checked + .SelectAddOns_buttonStyledCheck__3cS6d:before{
  content:'Remove';
  background-color: #fddfdc;
  color:#e00101;
  font-weight: normal;
  font-weight: initial;
}
.Invoices_column__1mtLK {
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}

.Invoices_filters__2cK2s {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  grid-gap: 0;
  gap: 0;
}

.Invoices_filters__2cK2s .Invoices_button__31jay {
  display: flex;
  padding: 0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}
@media (max-width: 768px){
  .Invoices_filters__2cK2s{
    margin-top: 20px;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 0;
    gap: 0;
  }
}
@media (max-width: 500px){
  .Invoices_filters__2cK2s{
    margin-top: 20px;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  .Invoices_filters__2cK2s .Invoices_button__31jay{
    margin-left: 0;
    margin-right: 6px;
  }
}
.Invoices_chip__3001D {
  padding: 1px 4px;
  border-radius: 4px;
}

.Invoices_filters__2cK2s .Invoices_button__31jay.Invoices_unpaid__1JXtX,
.Invoices_chip__3001D.Invoices_unpaid__1JXtX {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.Invoices_filters__2cK2s .Invoices_button__31jay.Invoices_paid__1TCS5,
.Invoices_chip__3001D.Invoices_paid__1TCS5 {
  color: rgb(0, 107, 80);
  background-color: rgba(0, 107, 80, 0.1);
}

.Invoices_filters__2cK2s .Invoices_button__31jay.Invoices_past__2yGVQ,
.Invoices_chip__3001D.Invoices_past__2yGVQ {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.Invoices_filters__2cK2s .Invoices_button__31jay.Invoices_selected__2qM-7 {
  border-color: rgba(0, 0, 0, 0.5);
}

.Invoices_statusChip__2RDZ4 {
  padding: 2px 6px;
  border-radius: 4px;
}

.Invoices_card__3zcG5 {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 5px;
  flex: 1 1
}

.Invoices_box__kQ2vM {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1px;

}

.Invoices_paymentDialog__w1M-t {
  padding: 20px 30px 40px 30px;
  min-width: 600px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .Invoices_paymentDialog__w1M-t.Invoices_mobile__3Kyku {
    padding: 20px 20px 40px 20px;
  }
}

.Invoices_payAmoutButton__383YR {
  padding: 24px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top: 5px;
  transition: 0.2s;
  color: #000000
}

.Invoices_payAmoutButton__383YR:hover {
  background-color: #f0f0f0;
}

.Invoices_payAmoutButton__383YR.Invoices_full__1eQqy {
  background-color: var(--accent-shade);
}

.Invoices_payAmoutButton__383YR:hover,
.Invoices_payAmoutButton__383YR.Invoices_full__1eQqy:hover {
  background-color: var(--accent-shade-d);
}

.Invoices_closeDialog__2rWut {
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -5px;
}

.Invoices_consolidatedInvoiceRow__130d6 {
  cursor: pointer;
}

.Invoices_consolidatedInvoiceRow__130d6:hover {
  color: #0000EE
}

.Invoices_loadingContainer__33qdK {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.Quote_actionField__1NwTi{
  background-color: #f1f3f4;
  min-height:100vh;
  padding-top:100px;
  padding-bottom: 100px;
}
.Quote_header__GUlYg{
  margin:0px 0px 30px 0px;
  text-align: center;

}
.Quote_quoteBody__1aIYU{
  background-color: #fff;
  border-radius: 5px;
  padding:50px 30px 50px 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 30px 1px #e9ecee;
}

@media screen and (max-width: 600px){
  .Quote_quoteBody__1aIYU{
    padding:30px 15px 30px 15px;
  }
}

.Quote_bottomBar__1yLuW{
    height:60px;
    background-color:#fff;
    box-shadow: 0 0 20px 1px rgba(0,0,0,0.06);
    padding:8px 20px;
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    flex:1 1;
    overflow: hidden;
    justify-content: center;
    z-index: 900;
}
.Quote_nextStepButton__G5W-h,.Quote_prevStepButton__16bUS{
    height:46px;
    width:150px;
    background-color: var(--primary-color);
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#fff;
    border:0;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
}
.Quote_prevStepButton__16bUS{
  width:90px;
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    margin-right: 20px;
}
.Quote_prevStepButton__16bUS:hover{
    background-color: #fff;
}
.Quote_nextStepButton__G5W-h:hover{
    background-color: var(--primary-color-d);
}
.Quote_nextStepButton__G5W-h:disabled{
    background-color: rgba(0,0,0,0.15);
    color:#fff;
    cursor:not-allowed
}

.Quote_greyButton__rmktX,.Quote_whiteButton__3rzSq{
  width:90px;
  background-color: rgba(0,0,0,0.15);
    color:#fff;
    border:1px solid rgba(255,255,255,0.35);
  margin-right: 10px;
  justify-content: center;
}
.Quote_whiteButton__3rzSq{
  background-color: #fff;
  color:var(--brand-accent);
  margin-right:25px; 
}
.Quote_greyButton__rmktX{
  width:160px;
}
.Quote_greyButton__rmktX:hover{
  background-color: rgba(255,255,255,0.05);
}

.Quote_paymentDialog__2kZkV{
  padding:20px 30px 40px 30px;
  min-width: 600px;
  position: relative;
}

@media screen and (max-width: 600px){
  .Quote_paymentDialog__2kZkV.Quote_mobile__3QQrn{
    padding:0.5rem;
    min-width: 0;
    overflow-x: hidden;
  }
}
.Quote_payAmoutButton__1j1j4{
  padding:24px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top:5px;
  transition: 0.2s;
  color:#000000
}

.Quote_payAmoutButton__1j1j4:hover{
  background-color: #f0f0f0;
}

.Quote_payAmoutButton__1j1j4.Quote_full__2TnmZ{
  background-color: var(--accent-shade);
}
.Quote_payAmoutButton__1j1j4:hover,.Quote_payAmoutButton__1j1j4.Quote_full__2TnmZ:hover{
  background-color: var(--accent-shade-d);
}
.Quote_closeDialog__1Abvs{
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -5px;
}

.Pdf_button__KH10q{
    height:46px;
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
    width:130px;
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    margin-right: 20px;
}
.Pdf_button__KH10q:hover{
    background-color: #fff;
}
.BookingManagement_column__RrDyl {
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}

.BookingManagement_card__1AQ7x {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 5px;
  flex: 1 1
}

.BookingManagement_vehicleImage__LmtvJ {
  width: 90px;
  height: 74px;
  overflow: hidden;
  border-radius: 6px;
  margin: -5px;
  margin-right: 12px;
}

.BookingManagement_fuelIndicator__26W03 {
  height: 0.6em;
  background-color: rgba(33, 153, 117, 0.1);
  border-radius: 0.5em;
  margin-top: 9px;
  position: relative;
}

.BookingManagement_fuelIndicator__26W03 div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(59, 199, 157);
  border-radius: 0.5em;
}

.BookingManagement_box__TVNMa {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1px;

}

.BookingManagement_damageImages__2opfA {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: center;
}

.BookingManagement_damageImages__2opfA .BookingManagement_image__2MaDm {
  width: 80px;
  height: 56px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 2px;
}

.BookingManagement_damageImages__2opfA .BookingManagement_image__2MaDm img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.BookingManagement_damageImages__2opfA .BookingManagement_image__2MaDm .BookingManagement_tag__3pUyJ {
  position: absolute;
  top: 3px;
  left: 3px;
  display: flex;
  align-items: center;
  padding: 2px;
  padding-right: 8px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #fff;
}

.BookingManagement_damageImages__2opfA .BookingManagement_image__2MaDm .BookingManagement_tag__3pUyJ p {
  margin: 0;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(187, 3, 3);
  color: #fff;
  font-weight: 700;
  margin-right: 3px;
}

.BookingManagement_checkCard__RzJXw {
  padding: 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: 100%;
}

.BookingManagement_logo__1HX23 {
  height: 76px;
  width: auto;
}

.BookingManagement_logo__1HX23 img {
  height: 100%;
  width: auto;
}

/* swap ui css */
.BookingManagement_swapDetailsBox__MsVn- {
  display: flex;
  padding: 20px 0px 0px 20px;
  justify-content: space-between;
}

.BookingManagement_swapNoteBox__1T5f4 {
  background: #ececec;
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.BookingManagement_updatesLoader__3GGVt {
  padding-left: 10px;
}
.BookingManagement_header__6YzOw{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.BookingManagement_headerButtons__Bcq3c{
  display: flex;
  flex:1 1;
  justify-content: flex-end;
}
@media (max-width: 450px) {
  .BookingManagement_header__6YzOw{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
  }
  .BookingManagement_headerButtons__Bcq3c{
    display: flex;
    flex:1 1;
  }
}
.verificationDialog_listContainer__3WcGX {
  padding: "5px 20px";
}

.verificationDialog_listContainer__3WcGX li {
  list-style: none;
}
@media print{body{overflow:visible}.break{page-break-after:always;-webkit-column-break-after:page;break-after:page;top:0px}}@page{size:auto;margin-bottom:20mm}.loading-message{margin-top:150px}
.Summary_appBody__1Pe-5{
    background-color: #f1f3f4;
    flex:1 1
}
.Summary_actionField__3IxJ4{
  background-color: #f1f3f4;
  min-height:100vh;
  padding-top:100px;
  padding-bottom: 100px;
}

.Summary_container__2xExm{
  display: flex;
  flex-direction: column;
}
.Summary_header__39243{
  margin:0px 0px 30px 0px;
  text-align: center;

}

.Summary_summaryBody__76WAl{
  background-color: #fff;
  border-radius: 5px;
  padding:50px 30px 50px 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 30px 1px #e9ecee;
}
@media screen and (max-width: 600px){
  .Summary_summaryBody__76WAl{
    width:250%;
    transform: scale(0.4) translate(-75%,-75%);
    -webkit-transform:scale(0.4) translate(-75%,-75%);
    -moz-transform: scale(0.4) translate(-75%,-75%);
    -o-transform: scale(0.4) translate(-75%,-75%);
  }
}

.Summary_bottomBar__pQFMd{
    height:60px;
    background-color:#fff;
    box-shadow: 0 0 20px 1px rgba(0,0,0,0.06);
    padding:8px 60px;
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    flex:1 1;
    overflow: hidden;
    justify-content: center;
    z-index: 900;
}
.Summary_nextStepButton__3kIRp,.Summary_prevStepButton__fl12b{
    height:46px;
    width:150px;
    background-color: var(--primary-color);
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#fff;
    border:0;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
}
.Summary_prevStepButton__fl12b{
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
}
.Summary_prevStepButton__fl12b:hover{
    background-color: #fff;
}
.Summary_nextStepButton__3kIRp:hover{
    background-color: var(--primary-color-d);
}
.Summary_nextStepButton__3kIRp:disabled{
    background-color: rgba(0,0,0,0.15);
    color:#fff;
    cursor:not-allowed
}

.Summary_greyButton__2cyTq,.Summary_whiteButton__g8PBf{
  width:90px;
  background-color: rgba(0,0,0,0.15);
    color:#fff;
    border:1px solid rgba(255,255,255,0.35);
  margin-right: 10px;
  justify-content: center;
}
.Summary_whiteButton__g8PBf{
  background-color: #fff;
  color:var(--brand-accent);
  margin-right:25px; 
}
.Summary_greyButton__2cyTq{
  width:160px;
}
.Summary_greyButton__2cyTq:hover{
  background-color: rgba(255,255,255,0.05);
}
.flatpickr-container{width:100%;position:relative}.flatpickr-container .flatpickr-wrapper{margin-right:0px !important}.flatpickr-container .flatpicker-date-label{position:absolute;display:block;top:-8px;z-index:1;left:8px;background:#fff;padding:0px 5px;font-size:10px}.flatpickr-container .flatpicker-date-label .flat-picker-required{position:relative;top:2px;left:2px}.flatpickr-container .flat-picker-error{color:#d13135;margin-left:14px;margin-right:14px;margin:0;font-size:.75rem;margin-top:3px;text-align:left;font-family:"Roboto","Helvetica","Arial",sans-serif;font-weight:400;line-height:1.66;letter-spacing:.03333em}.flatpickr-container .flatpickr-input{min-height:38px;font-size:14px;width:100%;border:1px solid #ccc;border-radius:4px;outline:none;padding-left:12px}.flatpickr-container .flatpickr-input:focus{border:2px solid var(--theme-primary)}.flatpickr-container .flatpickr-wrapper{width:100%;display:block !important}.flatpickr-container img.datePickerClearIcon{left:-30px;top:12px;position:relative;cursor:pointer;width:12px}.simple-time-picker .flatpickr-wrapper .flatpickr-input{width:90%}.date-time-picker .flatpickr-wrapper{min-width:0px !important}.date-time-picker .flatpickr-wrapper .flatpickr-input{min-width:0px}.fleet-calendar-container .fleet-calendar{cursor:pointer;width:56px;color:#fff;height:42px;background-color:#fff;border-radius:10px;border:1px solid #ccc;outline:none;padding:12px 14px 0px}.flatpickr-container.required-date .flatpicker-date-label{top:-9px}.replacement-reason-date-time .flatpickr-input{box-sizing:border-box;padding-left:10px}.MuiPaper-root.MuiMenu-paper{max-height:400px}
.CustomerPortal_rightCol__rjlj1{
  width:100%;
  display: flex;
  flex-direction: column;
  flex:1 1;
  margin: 130px 0px 50px 0px;
  position: relative;
}

@media only screen and (min-width: 600px) {
  .CustomerPortal_rightCol__rjlj1{
    margin-left: 200px;
  }
}
@media only screen and (min-width: 1280px) {
  .CustomerPortal_rightCol__rjlj1{
    margin-left: 250px;
  }
}
.VehicleTracking_screen__1rh0W{
  top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.VehicleTracking_pageTitle__k6k2C{
  margin-bottom: 10px;
}
.VehicleTracking_queryCard__1-kbZ{
  position: absolute;
  top:25px;
  left:25px;
  padding:10px 4px 4px 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0,0,0,0.1);
  border-radius: 6px;
  overflow: hidden;
}
.VehicleTracking_queryCard__1-kbZ h3{
  margin:0;
  font-size: 14px;
  padding-left: 14px;
}
.VehicleTracking_searchBox__2HCxf{
  border-radius: 5px;
  margin-top:10px;
  display: flex;
  box-shadow: 0 3px 10px 1px rgba(0,0,0,0.1);
}
.VehicleTracking_searchBox__2HCxf .VehicleTracking_block__qQUeU{
  position: relative;
  border-left: 1px solid #E5E5E5;
  width: 200px;
}
.VehicleTracking_searchBox__2HCxf .VehicleTracking_block__qQUeU:first-child{
  border:0;
  width: 230px;
}
.VehicleTracking_searchBox__2HCxf .VehicleTracking_button__1wXwo{
  background-color: var(--theme-primary); 
  width:60px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  color:#fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}
.VehicleTracking_searchBox__2HCxf .VehicleTracking_button__1wXwo:hover{
  background-color: var(--theme-primary-dark);
}
.VehicleTracking_searchBox__2HCxf .VehicleTracking_inputLabel__1Vdtw{
  position:absolute;
  display: flex;
  top:8px;
  left:14px;
  font-size: 13px;
  color:rgba(0,0,0,0.6);
  right:15px;
  justify-content: space-between;
}
.VehicleTracking_checkbox__2TZ3x{
  position: absolute;
  top:0;
  left:0;
  width:15px !important;
  bottom:0;
  height: 15px !important;
}

.VehicleTracking_mapModule__6Jwla{
  position: relative;
  right:50px;
  height:100%;
  overflow: hidden;
}

@media (max-width: 1279px){
  .VehicleTracking_mapModule__6Jwla{
    right:29px;
  }
}
@media (max-width: 599px){
  .VehicleTracking_mapModule__6Jwla{
    right: 0;
  }
}

.VehicleTracking_mapContainer__1f_HI{
  position: absolute;
  top:0;
  left:0;
  bottom:0px;
  right:0;
  border-radius: 10px;
  outline: none;
}

.VehicleTracking_module__1_-YL{
  padding:10px 20px;
  display: flex;
  height:260px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}

.VehicleTracking_module__1_-YL.VehicleTracking_flexible__A9Bv-{
  height:auto
}

.VehicleTracking_module__1_-YL .VehicleTracking_chartHeadline__2urf4{
  position: absolute;
  top:16px;
  left:30px;
  right:30px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  align-items: center;
}

.VehicleTracking_filterInput__1F-Rk{
  padding:6px 10px;
  border-radius: 50px;
  font-size: 12px;
  /* font-weight: 700; */
  background-color: #f9f9f9;
  display: block;
  text-align: center;
  box-sizing: border-box;
  transition: 0.2s;
  cursor: pointer;
  margin-left:5px
}

.VehicleTracking_filterInput__1F-Rk:hover{
  background-color: #f4f4f4;
}

.VehicleTracking_module__1_-YL .VehicleTracking_legendWrap__XajB2{
  width:150px;
  z-index: 2;
  min-height: 280px;
  padding:40px 0 39px 10px;
  box-sizing: border-box;
  text-align: right;
  overflow-x: hidden;
}

.VehicleTracking_vglegend__1_OWk{
  padding-left: 3px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 20px;
}
.VehicleTracking_vglegend__1_OWk .VehicleTracking_tick__2i2Io{
  height:12px;
  width:12px;
  margin-right: 5px;
  border-radius: 6px;
}
.VehicleTracking_vglegend__1_OWk span{
  display: flex;
  flex:1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.VehicleTracking_vglegend__1_OWk .VehicleTracking_remove__WIUml{
  opacity:0; 
}

.VehicleTracking_vglegend__1_OWk:hover{
  background-color: #f9f9f9;
}

.VehicleTracking_vglegend__1_OWk:hover .VehicleTracking_remove__WIUml{
  opacity:1
}

.VehicleTracking_module__1_-YL .VehicleTracking_chartWrap__qmOzZ{
  position: absolute;
  top:10px;
  left:0;
  bottom:0;
  width:60%;
  padding:20px 0 20px 30px;
  box-sizing: border-box;
}

.VehicleTracking_module__1_-YL.VehicleTracking_outline__21Tct{
  border:1px solid #e2e2e2;
}
.VehicleTracking_inputsWrap__vLeOx{
  display: flex;
}
.VehicleTracking_inputBox__3Rq1p{
  min-width:180px;
  margin-right: 10px;
}

.VehicleTracking_infoCard__mlj6j{
  border-radius:10px;
  background-color: #f5f5f5;
  font-size: 12px;
  padding:10px 12px;
}
.VehicleTracking_infoCard__mlj6j p{
  margin:0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


.Marker_eventMarker__3rJRK{
  transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  height:12px;
  width:12px;
  cursor:pointer;
  position: relative;
}
.Marker_infoPoper__KLc9M{
  position: absolute;
  bottom: 30px;
  left:6px;
  width:130px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px 1px rgba(0,0,0,0.1);
  transform: translateX(-50%);
  -moz-transform:translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 1000;
  padding:10px;
}
.Marker_infoPoper__KLc9M::after{
  content: "";
  position: absolute;
  width:16px;
  height: 16px;
  background-color: #fff;
  transform: rotate(45deg) translateX(-8px);
  border-radius: 3px;
  bottom: -10px;
  left:50%;
}
.Marker_infoPoper__KLc9M h3{
  margin:0; 
}
.Marker_infoPoper__KLc9M p{
  margin:3px 0; 
  display: flex;
  align-items: center;
  font-size: 12px;
}
.Marker_endPointMarker__1ntl5{
  background-color: #fff;
  padding: 4px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 0px 10px 1px rgba(0,0,0,0.25);
  width: 36px;
  height: 20px;
  transform: translateX(-22px);
}
.Marker_endPointMarker__1ntl5 div{
  height: 20px;
  width: 36px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  border-radius: 5px; 
  color: #fff;
  font-size: 12px; 
  font-weight: 700 ;
  position: relative;
}
.Marker_endPointMarker__1ntl5::before{
  content: "";
  position: absolute;
  width:12px;
  height: 12px;
  background-color: #fff;
  transform: rotate(45deg) translateX(-8px);
  border-radius: 3px;
  top: 0px;
  left:50%;
}

.TelematicsView_cardLeft__ag7nM{
  position: absolute;
  top:110px;
  left:20px;
  background-color: #fff;
  border-radius: 10px;
  padding:15px;
  padding-top:5px;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 4px 10px 1px rgba(0,0,0,0.1);
}

.TelematicsView_cardLeft__ag7nM h2{
  font-size: 14px;
  margin-top:13px;
  margin-bottom: 5px;
}

.TelematicsView_cardRight__3AH2d{
  position: absolute;
  top:110px;
  right:20px;
  bottom:20px;
  width:280px;
  display: flex;
  flex-direction: column;
}
.TelematicsView_vehicleInfoBox__1X-nj{
  background-color: #fff;
  box-shadow: 0 0 30px 1px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 13px;
  padding:5px;
}
.TelematicsView_mobiliseButton__1W2gm{
  background: #c54740 !important;
  font-weight: 600 !important;
  width: 100% !important;
  border-radius: 10px;
  transition: 0.2s;
}
.TelematicsView_mobiliseButton__1W2gm:hover{
  opacity: 0.9;
}
.TelematicsView_legendBox__3u_aM{
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 30px 1px rgba(0,0,0,0.1);
  padding:0 10px;
}

.TelematicsView_vehicleImage__yHR4x{
  width:100px;
  height:70px;
  background:#f9f9f9;
  margin:10px 0 10px 10px;
  border-radius: 6px;
  overflow: hidden;
}
.TelematicsView_vehicleImage__yHR4x img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.TelematicsView_label__WeNpI{
  margin:3px 0;
  font-size: 12px;
  color:#616161;
}
.TelematicsView_coloredInfoCard__3f52t{
  display: flex;
  justify-content: center;
  flex:1 1;
  margin-right:10px;
  height:70px;
  background-color: #646464;
  border-radius: 8px;
  flex-direction: column;
  padding:10px;
  color:#fff;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 14px;
}
.TelematicsView_coloredInfoCard__3f52t.TelematicsView_teal__3BzuD{
  background-color: #00AA9D;
}
.TelematicsView_coloredInfoCard__3f52t.TelematicsView_blue__2XEs9{
  background-color: #286CD9;
}
.TelematicsView_coloredInfoCard__3f52t.TelematicsView_orange__vRnrL{
  background-color: #EA8133;
}
.TelematicsView_coloredInfoCard__3f52t .TelematicsView_label__WeNpI{
  color:#fff;
  margin-bottom:6px;
  font-weight: normal;
  font-weight: initial;
  font-size: 12px;
}
.TelematicsView_legend__PG2IB{
  width:50%;
  font-size: 13px;
  color:#000000;
  box-sizing: border-box;
  margin:0;
  position: relative;
  cursor: pointer;
  display: flex;
  padding:4px 0;
  border-radius: 4px;
  transition: 0.2s;
  border:1px dashed transparent
}
.TelematicsView_legend__PG2IB span{
  display: inline-flex;
  align-items: center;
  margin-left:5px;
}
.TelematicsView_legend__PG2IB.TelematicsView_active__1YYKS{
  font-weight: 700;
  border-color: #000;
}
.Bookings_column__weGBt{
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}

.Bookings_filters__1qVFk{
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  grid-gap: 0;
  gap: 0;
}
.Bookings_filters__1qVFk .Bookings_button__ZByRr{
  display: flex;
  padding:0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border:1px solid transparent;
  cursor: pointer;
}
@media (max-width: 768px){
  .Bookings_filters__1qVFk{
    margin-top: 20px;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 0;
    gap: 0;
  }
}
@media (max-width: 500px){
  .Bookings_filters__1qVFk{
    margin-top: 20px;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  .Bookings_filters__1qVFk .Bookings_button__ZByRr{
    margin-left: 0;
    margin-right: 6px;
  }
}
.Bookings_chip__3YxkZ{
  padding:1px 4px;
  border-radius: 4px;
}

.Bookings_filters__1qVFk .Bookings_button__ZByRr.Bookings_current__cmiQR,.Bookings_chip__3YxkZ.Bookings_current__cmiQR{
  color: rgba(0,91,227);
  background-color: rgba(0,91,227,0.08);
}
.Bookings_filters__1qVFk .Bookings_button__ZByRr.Bookings_upcoming__2HryC,.Bookings_chip__3YxkZ.Bookings_upcoming__2HryC{
  color: rgba(162,94,4);
  background-color: rgba(162,94,4,0.08);
}
.Bookings_filters__1qVFk .Bookings_button__ZByRr.Bookings_past__26GPu,.Bookings_chip__3YxkZ.Bookings_past__26GPu{
  color: rgba(180,0,0);
  background-color: rgba(180,0,0,0.08);
}
.Bookings_filters__1qVFk .Bookings_button__ZByRr.Bookings_selected__3grrG{
  border-color: rgba(0,0,0,0.5);
}

.Bookings_statusChip__1im1R{
  padding:2px 6px;
  border-radius: 4px;
}
.Vehicles_column__2yQFI {
  display: flex;
  flex-direction: column;
  padding: 130px 0px 30px 50px;
  overflow: auto;
}
.Vehicles_tableContainer__2aFeV { 
  width: 100%; 
  padding-top: 10px;
  margin-top: 10px;
}
.Vehicles_filterContainer__3qmAJ{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
@media only screen and (max-width:425px) {
  .Vehicles_filterContainer__3qmAJ{
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
}
.Vehicles_filters__15mCs {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  align-items: stretch;
}

.Vehicles_filters__15mCs .Vehicles_button__COPfU {
  display: flex;
  padding: 0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}

.Vehicles_chip__35Ses {
  padding: 1px 4px;
  border-radius: 4px;
}

.Vehicles_filters__15mCs .Vehicles_button__COPfU.Vehicles_unpaid__4S6pg,
.Vehicles_chip__35Ses.Vehicles_unpaid__4S6pg {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.Vehicles_filters__15mCs .Vehicles_button__COPfU.Vehicles_paid__19jrq,
.Vehicles_chip__35Ses.Vehicles_paid__19jrq {
  color: rgb(0, 107, 80);
  background-color: rgba(0, 107, 80, 0.1);
}

.Vehicles_filters__15mCs .Vehicles_button__COPfU.Vehicles_past__-gHnQ,
.Vehicles_chip__35Ses.Vehicles_past__-gHnQ {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.Vehicles_filters__15mCs .Vehicles_button__COPfU.Vehicles_selected__2gzAu {
  border-color: rgba(0, 0, 0, 0.5);
}

.Vehicles_statusChip__OBWlp {
  padding: 2px 6px;
  border-radius: 4px;
}

.Vehicles_thumbnail__1O8Tk {
  width: 80px;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  margin: -5px 0
}

.Vehicles_thumbnail__1O8Tk img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Vehicles_shade__1xi0f {
  border: 1px dashed #d8d8d8;
  padding: 6px;
  border-radius: 6px;
  margin: 1px
}

.Vehicles_shade__1xi0f.Vehicles_item__2FknH {
  border: 0;
  background-color: #f0f0f0;
  padding: 10px 10px;
  margin: 2px 0;
  cursor: pointer;
}

.Vehicles_shade__1xi0f.Vehicles_item__2FknH.Vehicles_red__2JQau {
  background-color: rgba(231, 0, 0, 0.122);
  color: rgb(166, 28, 28);
  margin-bottom: 10px;
}

.Vehicles_shade__1xi0f.Vehicles_item__2FknH.Vehicles_red__2JQau.Vehicles_selected__2gzAu {
  background-color: #a83030;
  color: #fff
}

.Vehicles_shade__1xi0f.Vehicles_item__2FknH:hover {
  background-color: #e9e9e9;
}

.Vehicles_shade__1xi0f.Vehicles_item__2FknH.Vehicles_selected__2gzAu {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 700;
  margin: 4px 0;
}

.Vehicles_driverButton__Wm0CF {
  align-self: center;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px 6px;
  margin: 0 -6px
}

.Vehicles_driverButton__Wm0CF p {
  margin: 0
}

.Vehicles_driverButton__Wm0CF:hover {
  background-color: #e2e2e2;
}

.Vehicles_textButton__Biv9T {
  display: inline-flex;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid;
}
.account_column__pTO-m{
  display: flex;
  flex-direction: column;
}

.account_avatar__3Y_dj{
 height: 70px;
 width:70px;
 border-radius: 16px;
 overflow: hidden; 
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #e2e2e2;
}
.account_box__I6Uwd{
  margin-top: 20px;
  border:1px solid #e9e9e9;
  border-radius: 10px;
  padding:20px;
}
.Profile_profile__Td2rp {
  max-width: 680px;
  margin: auto;
}

.Profile_avatar__QukLO {
  height: 70px;
  width: 70px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
}

.Profile_box__1MKW9 {
  margin-top: 20px;
  border: 1px dashed #e9e9e9;
  border-radius: 10px;
  padding: 15px;
}

.Profile_card__hNrB1 {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  flex: 1 1
}

.Profile_dlImage__bMtq6 {
  height: 56px;
  width: 90px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
  position: relative
}

.Profile_dlImage__bMtq6:first-child {
  margin-right: 4px;
}

.Profile_dlImage__bMtq6 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Vehicle_column__29DJU {
  display: flex;
  flex-direction: column;
  padding-bottom: 130px;
}

.Vehicle_card__1HRBC {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 14px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 5px;
  flex: 1 1
}

.Vehicle_card__1HRBC.Vehicle_noBg__LpPWe {
  background-color: transparent;
  margin-top: 0;
}

.Vehicle_vehicleImage__11ZGR {
  width: 90px;
  height: 74px;
  overflow: hidden;
  border-radius: 6px;
  margin: -5px;
  margin-right: 12px;
}

.Vehicle_fuelIndicator__1OWvR {
  height: 0.6em;
  background-color: rgba(33, 153, 117, 0.1);
  border-radius: 0.5em;
  margin-top: 9px;
  position: relative;
}

.Vehicle_fuelIndicator__1OWvR div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(59, 199, 157);
  border-radius: 0.5em;
}

.Vehicle_box__2C37e {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 20px;
}

.Vehicle_damageImages__3oKV0 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: center;
}

.Vehicle_damageImages__3oKV0 .Vehicle_image__3ZqiD {
  width: 80px;
  height: 56px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 2px;
  background-color: #f0f0f0;
}

.Vehicle_damageImages__3oKV0 .Vehicle_image__3ZqiD img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Vehicle_damageImages__3oKV0 .Vehicle_image__3ZqiD .Vehicle_tag__1PnAF {
  position: absolute;
  top: 3px;
  left: 3px;
  display: flex;
  align-items: center;
  padding: 2px;
  padding-right: 8px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #fff;
}

.Vehicle_damageImages__3oKV0 .Vehicle_image__3ZqiD .Vehicle_tag__1PnAF p {
  margin: 0;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(187, 3, 3);
  color: #fff;
  font-weight: 700;
  margin-right: 3px;
}

.Vehicle_checkCard__jpvup {
  padding: 12px 16px 16px 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: 100%;
  border: 1px solid transparent;
}

.Vehicle_checkCard__jpvup.Vehicle_error__1eGhx {
  border-color: rgba(209, 49, 53, 0.5);
}

.Vehicle_checkCard__jpvup.Vehicle_error__1eGhx .Vehicle_name__2lUcT {
  color: #D13135;
}

.Vehicle_odometerImage__3ajeP {
  width: 70px;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  margin-left: 5px;
  overflow: hidden;
  position: relative;
}

.Vehicle_odometerImage__3ajeP.Vehicle_empty__-GXBb .Vehicle_upload__1_w6w {
  opacity: 1;
}

.Vehicle_odometerImage__3ajeP .Vehicle_upload__1_w6w {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.2s;
}

.Vehicle_odometerImage__3ajeP:hover .Vehicle_upload__1_w6w {
  opacity: 1;
}
.Drivers_column__2xONB {
  display: flex;
  flex-direction: column;
}

.Drivers_filters__Ud9FA {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  align-items: stretch;
}

.Drivers_filters__Ud9FA .Drivers_button__35dES {
  display: flex;
  padding: 0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}

.Drivers_chip__3DMd6 {
  padding: 1px 4px;
  border-radius: 4px;
}

.Drivers_filters__Ud9FA .Drivers_button__35dES.Drivers_current__2RRAd,
.Drivers_chip__3DMd6.Drivers_current__2RRAd {
  color: rgba(0, 91, 227);
  background-color: rgba(0, 91, 227, 0.08);
}

.Drivers_filters__Ud9FA .Drivers_button__35dES.Drivers_upcoming__nkSh-,
.Drivers_chip__3DMd6.Drivers_upcoming__nkSh- {
  color: rgba(162, 94, 4);
  background-color: rgba(162, 94, 4, 0.08);
}

.Drivers_filters__Ud9FA .Drivers_button__35dES.Drivers_past__28gDQ,
.Drivers_chip__3DMd6.Drivers_past__28gDQ {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.Drivers_filters__Ud9FA .Drivers_button__35dES.Drivers_selected__26Qy9 {
  border-color: rgba(0, 0, 0, 0.5);
}

.Drivers_statusChip__1AP4Y {
  padding: 2px 6px;
  border-radius: 4px;
}

.Drivers_addDialog__27GWu {
  width: 460px;
  padding: 25px
}

.Drivers_profile__2d_8J {
  max-width: 680px;
  margin: auto;
}

.Drivers_avatar__3c9Ba {
  height: 70px;
  width: 70px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
}

.Drivers_box__2KiQ9 {
  margin-top: 20px;
  border: 1px dashed #e9e9e9;
  border-radius: 10px;
  padding: 15px;
}

.Drivers_card__2epQZ {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 5px;
  flex: 1 1
}

.Drivers_dlImage__1f51X {
  height: 56px;
  width: 90px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
}

.Drivers_dlImage__1f51X:first-child {
  margin-right: 4px;
}

.Drivers_dlImage__1f51X img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.BookingConfirmation_paymentDialog__3YqfZ{
  padding:20px 30px 40px 30px;
  min-width: 600px;
  position: relative;
}
@media screen and (max-width: 600px){
  .BookingConfirmation_paymentDialog__3YqfZ.BookingConfirmation_mobile__1fqJf{
    min-width: 0;
    padding:20px 20px 40px 20px;
  }
}
.BookingConfirmation_payAmoutButton__2ufY_{
  padding:24px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top:5px;
  transition: 0.2s;
  color:#000000
}

.BookingConfirmation_payAmoutButton__2ufY_:hover{
  background-color: #f0f0f0;
}

.BookingConfirmation_payAmoutButton__2ufY_.BookingConfirmation_full__3H3Cp{
  background-color: var(--accent-shade);
}
.BookingConfirmation_payAmoutButton__2ufY_:hover,.BookingConfirmation_payAmoutButton__2ufY_.BookingConfirmation_full__3H3Cp:hover{
  background-color: var(--accent-shade-d);
}
.BookingConfirmation_closeDialog__1_XMA{
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -5px;
}

.BookingConfirmation_stripeContainer__1-GsI{
  width:40%;
  margin: 0 auto;
}
@media screen and (max-width: 600px){
  .BookingConfirmation_stripeContainer__1-GsI{
    width:100%;
  }
}
.CreditNotes_column__3CmeC {
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}
.CreditNotes_headerContainer__1ndao{
  display: flex;
  flex:1 1;
  align-items: center;
}
.CreditNotes_headBalContainer__1CZbp{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CreditNotes_filters__1g37b {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  grid-gap: 0;
  gap: 0;
}
@media (max-width: 1046px){
  .CreditNotes_headerContainer__1ndao{
    display: block;
  }
  .CreditNotes_filters__1g37b{
    justify-content: flex-start;
    margin-top: 10px;
  }
}
.CreditNotes_filters__1g37b .CreditNotes_button__Kvo0D {
  display: flex;
  padding: 0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}
@media (max-width: 768px){
  .CreditNotes_filters__1g37b{
    margin-top: 20px;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 0;
    gap: 0;
  }
}
@media (max-width: 500px){
  .CreditNotes_filters__1g37b{
    margin-top: 20px;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
  .CreditNotes_filters__1g37b .CreditNotes_button__Kvo0D{
    margin-left: 0;
    margin-right: 6px;
  }
}
.CreditNotes_chip__Rry-S {
  padding: 1px 4px;
  border-radius: 4px;
}

.CreditNotes_filters__1g37b .CreditNotes_button__Kvo0D.CreditNotes_unpaid__NAA6H,
.CreditNotes_chip__Rry-S.CreditNotes_unpaid__NAA6H {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.CreditNotes_filters__1g37b .CreditNotes_button__Kvo0D.CreditNotes_paid__QyVfX,
.CreditNotes_chip__Rry-S.CreditNotes_paid__QyVfX {
  color: rgb(0, 107, 80);
  background-color: rgba(0, 107, 80, 0.1);
}

.CreditNotes_filters__1g37b .CreditNotes_button__Kvo0D.CreditNotes_past__3O7GT,
.CreditNotes_chip__Rry-S.CreditNotes_past__3O7GT {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.CreditNotes_filters__1g37b .CreditNotes_button__Kvo0D.CreditNotes_selected__1B8iU {
  border-color: rgba(0, 0, 0, 0.5);
}

.CreditNotes_statusChip__314xx {
  padding: 2px 6px;
  border-radius: 4px;
}

.CreditNotes_card__1MRnX {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 5px;
  flex: 1 1
}

.CreditNotes_box__3DOwb {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1px;

}

.CreditNotes_paymentDialog__5C3bq {
  padding: 20px 30px 40px 30px;
  min-width: 600px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .CreditNotes_paymentDialog__5C3bq.CreditNotes_mobile__3mgjz {
    padding: 20px 20px 40px 20px;
  }
}

.CreditNotes_payAmoutButton__ptdg_ {
  padding: 24px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top: 5px;
  transition: 0.2s;
  color: #000000
}

.CreditNotes_payAmoutButton__ptdg_:hover {
  background-color: #f0f0f0;
}

.CreditNotes_payAmoutButton__ptdg_.CreditNotes_full__3dgEV {
  background-color: var(--accent-shade);
}

.CreditNotes_payAmoutButton__ptdg_:hover,
.CreditNotes_payAmoutButton__ptdg_.CreditNotes_full__3dgEV:hover {
  background-color: var(--accent-shade-d);
}

.CreditNotes_closeDialog__xuMMs {
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -5px;
}

.CreditNotes_loadingContainer__-dnjy {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
