.container{

  border-radius: 10px;
  padding:80px 40px;
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 100px;
  min-height: 100vh;
}


.avatar{
 height: 70px;
 width:70px;
 border-radius: 16px;
 overflow: hidden;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #e2e2e2; 
}

.box{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border:1px dashed #e5e5e5;
  border-radius: 10px;
  padding:15px;
}

.cardLeft{
  padding:40px;
  padding-right:30px;
}
.cardRight{
  padding:40px;
}
.validation{
  padding:8px 14px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  transition: 0.2s;
}
.inputError{
  color:#D13135;
  padding:5px 15px 5px 15px;
}

.bookingQuestion{
  padding:8px 14px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
}

.validation.error,.bookingQuestion.error{
  box-shadow:0 0 1px 1px rgba(209, 49, 53,0.8);
}
.rowFlex{
  display: flex;
  align-items: center;
}
.yesnoWrap,.dropdownWrap{
  width:200px;
  display: flex;
  margin:5px 0;
}
.dropdownWrap{
  background-color: #e8e8e8;
  border-radius: 8px;
}
.yesnoButton{
  display: flex;
  flex:1;
  background-color: #e2e2e2;
  font-size: 14px;
  color:#000000;
  justify-content: center;
  align-items: center;
  height:34px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}
.yesnoButton.left{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 4px;
}
.yesnoButton.right{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.yesnoButton.active{
  background-color: var(--primary-color);
  color:#fff
}
.bottomBar{
    height:60px;
    box-shadow: 0 0 20px 1px rgba(0,0,0,0.06);
    padding:7px 20px;
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    flex:1;
    z-index: 1001;
}
.bottomBar.mobile{
  padding:7px 20px;
}

.nextStepButton,.prevStepButton{
    height:46px;
    width:150px;
    background-color: var(--primary-color);
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#fff;
    border:0;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
     margin-left: 20px;
}
.prevStepButton{
    width:90px;
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    justify-content: center;
    margin-left: 0;
}
.prevStepButton:hover{
    background-color: #fff;
}
.nextStepButton.mobile{
  width: auto;
  flex:1;
  margin-left: 10px;
}
.nextStepButton:hover{
    background-color: var(--primary-color-d);
}
.nextStepButton:disabled{
    background-color: rgba(0,0,0,0.15);
    color:#fff;
    cursor:not-allowed
}
.footerUrl{
  text-decoration: none;
  float: right;
  padding:10px 0;
  font-size: var(--font-medium);
  display: inline;
}
.footerUrl:hover{
  text-shadow:0 0 0px #000;
  text-decoration: underline;
}
.footerUrl div{
  color:#000;
  text-shadow:0 0 0px var(--primary-color);
  display: inline-flex;
  align-items: flex-end;
}

@media screen and (max-width: 600px){
  .container{
    padding:80px 20px;
    padding-bottom: 160px;
  }
  .yesnoWrap,.dropdownWrap{
    width: 150px;
    display: flex;
    margin:5px 0;
  }
  .footerUrl{
    vertical-align: bottom;
  }
}