.column{
  display: flex;
  flex-direction: column;
}

.avatar{
 height: 70px;
 width:70px;
 border-radius: 16px;
 overflow: hidden; 
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #e2e2e2;
}
.box{
  margin-top: 20px;
  border:1px solid #e9e9e9;
  border-radius: 10px;
  padding:20px;
}