
.searchInfo{
  position: fixed;
  top:0;
  left:0;
  right:0;
  background-color: var(--primary-color);
  box-shadow: 0 5px 10px 1px #e2e2e2;
  z-index: 1100;
  padding:10px 20px
}
.searchInfo p{
  display: flex;
  align-items: center;
  margin:0;
  padding: 3px 0;
  color:#fff
}
.searchInfo p span{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
}
.rangeLine{
  height:2px;
  border-radius: 1px;
  width:15px;
  min-width: 15px;
  background-color: #ffffff;
  margin:0 10px;
}

.searchBox{
  background-color: var(--background-primary);
  border-bottom:1px solid var(--border-medium);
  display: flex;
  top:90px;
  z-index: 900;
  display: flex;
  align-items: center;
  transition: 0.2s;
}
@media screen and (max-width: 960px){
  .searchBox{
    border:0;
  }
  .searcContainer{
    margin-top: 0px;
  }
}

.searchInputWrap{
  display: flex;
  flex:1;
  margin:0 -20px;
}
.searchQueryGroup{
  display: flex;
  margin-right:15px;
}
.searchQueryGroup:last-child{
  margin:0;
}
.searchParam{
  cursor: pointer;
  position: relative;
  height: 64px;
  margin:5px 2px;
}

.searchParam.dateTime,.searchParam.rentalType{
    min-width: initial;
    width:136px;
    flex:none;
    justify-content: flex-end;
    overflow:initial
}
.dropInputCheck{
  position: absolute;
  top:0;
  left:0;
  width:40px;
  bottom:0;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  padding-top:1px;
  z-index: 2;
}
.dropInputCheckBox{
  color:var(--primary-color);
  font-size: 20px;
}
.dropInputCheckBox:checked{
  color:var(--primary-color)
}
.searchParamDisplay{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  flex:1;
  padding:0px 15px;
  padding-right: 10px;
  background-color: var(--accent-shade);
  border-radius: 10px;
  height: 64px;
  z-index: 1;
  transition: 0.2s;
  white-space: nowrap;
}
.searchParamDisplay:hover{
  background-color:var(--accent-shade-d);
}
.searchParamDisplay.disabled{
  color:#c5c5c5
}
.searchParamDisplay.disabled:hover{
  background-color: var(--accent-shade);
  cursor: not-allowed;
}
.searchParamDisplay p{
  margin:0;
  padding-bottom:6px;
  white-space: nowrap;
  user-select: none;
  font-size: var(--font-small);
  color: var(--primary-color);
}
.searchParam:last-of-type{
  border:0
}
.searchParamDisplay div{
  font-size: var(--font-medium);
}
.transparentInput{ 
  border:0;
  outline: none;
  cursor: pointer;
  width:136px;
  opacity: 0;
}
.searchButtonWrap{
  width:100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  padding:5px;
}
.searchButton{
  background-color: var(--primary-color);
  border-radius: 10px;
  width:100%;
  height:100%;
  min-height: 50px;
  border:0;
  cursor: pointer;
  outline:none;
  transition:0.2s;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
.searchButton:active{
  opacity:0.8
}
.searchButton:disabled{
  background-color: #d6d6d6;
  cursor: not-allowed;
}


.searchResult{
  border-radius: 20px;
  border-top-left-radius:30px;
  border-top-right-radius:30px;
  box-shadow: 0 0 20px 2px var(--shadow-color);
  padding:40px 0;
  padding-top:90px;
  z-index: 1;
  min-height:700px;
  display: flex;
  margin:0 -20px; 
  margin-top:-50px;
}
.seachFilter{
  width:250px;
  border-right:1px solid var(--shadow-color);
  display: flex;
  flex-direction: column;
}
.seachFilter h3{
  margin:0;
  padding-left:30px;
}
.filterCriteria{
  padding:0 20px 0 30px;
  margin-top:20px;
}
.filterCriteria h5{
  margin:0;
  margin-bottom: 10px;
}
.filterCriteriaItem{
  display: flex;
  align-items: center;
}
.filterCriteriaItem p{
  padding-left: 10px;
  font-size: var(--font-medium);
  margin:8px 0;
}
.blueTextButton{
  font-size: var(--font-small);
  color:var(--primary-color);
  font-weight:700;
  cursor: pointer;
  margin:5px 0;
}
.backToSelectionScreen {
  position: absolute;
  left: -5rem;
  top: 15px;
  transition: all 500ms ease-in-out;
}
@media(max-width: 1400px){
  .backToSelectionScreen{
    display:none;
  }
}