.screen{
  top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.pageTitle{
  margin-bottom: 10px;
}
.queryCard{
  position: absolute;
  top:25px;
  left:25px;
  padding:10px 4px 4px 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px 1px rgba(0,0,0,0.1);
  border-radius: 6px;
  overflow: hidden;
}
.queryCard h3{
  margin:0;
  font-size: 14px;
  padding-left: 14px;
}
.searchBox{
  border-radius: 5px;
  margin-top:10px;
  display: flex;
  box-shadow: 0 3px 10px 1px rgba(0,0,0,0.1);
}
.searchBox .block{
  position: relative;
  border-left: 1px solid #E5E5E5;
  width: 200px;
}
.searchBox .block:first-child{
  border:0;
  width: 230px;
}
.searchBox .button{
  background-color: var(--theme-primary); 
  width:60px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  color:#fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}
.searchBox .button:hover{
  background-color: var(--theme-primary-dark);
}
.searchBox .inputLabel{
  position:absolute;
  display: flex;
  top:8px;
  left:14px;
  font-size: 13px;
  color:rgba(0,0,0,0.6);
  right:15px;
  justify-content: space-between;
}
.checkbox{
  position: absolute;
  top:0;
  left:0;
  width:15px !important;
  bottom:0;
  height: 15px !important;
}

.mapModule{
  position: relative;
  right:50px;
  height:100%;
  overflow: hidden;
}

@media (max-width: 1279px){
  .mapModule{
    right:29px;
  }
}
@media (max-width: 599px){
  .mapModule{
    right: 0;
  }
}

.mapContainer{
  position: absolute;
  top:0;
  left:0;
  bottom:0px;
  right:0;
  border-radius: 10px;
  outline: none;
}

.module{
  padding:10px 20px;
  display: flex;
  height:260px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}

.module.flexible{
  height:auto
}

.module .chartHeadline{
  position: absolute;
  top:16px;
  left:30px;
  right:30px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  align-items: center;
}

.filterInput{
  padding:6px 10px;
  border-radius: 50px;
  font-size: 12px;
  /* font-weight: 700; */
  background-color: #f9f9f9;
  display: block;
  text-align: center;
  box-sizing: border-box;
  transition: 0.2s;
  cursor: pointer;
  margin-left:5px
}

.filterInput:hover{
  background-color: #f4f4f4;
}

.module .legendWrap{
  width:150px;
  z-index: 2;
  min-height: 280px;
  padding:40px 0 39px 10px;
  box-sizing: border-box;
  text-align: right;
  overflow-x: hidden;
}

.vglegend{
  padding-left: 3px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 20px;
}
.vglegend .tick{
  height:12px;
  width:12px;
  margin-right: 5px;
  border-radius: 6px;
}
.vglegend span{
  display: flex;
  flex:1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vglegend .remove{
  opacity:0; 
}

.vglegend:hover{
  background-color: #f9f9f9;
}

.vglegend:hover .remove{
  opacity:1
}

.module .chartWrap{
  position: absolute;
  top:10px;
  left:0;
  bottom:0;
  width:60%;
  padding:20px 0 20px 30px;
  box-sizing: border-box;
}

.module.outline{
  border:1px solid #e2e2e2;
}
.inputsWrap{
  display: flex;
}
.inputBox{
  min-width:180px;
  margin-right: 10px;
}

.infoCard{
  border-radius:10px;
  background-color: #f5f5f5;
  font-size: 12px;
  padding:10px 12px;
}
.infoCard p{
  margin:0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

