.appBody {
  background-color: #f1f3f4;
  flex: 1;
  height: calc(100vh - 239px);
}
.actionField {
  background-color: #f1f3f4;
  min-height: 100vh;
  padding-top: 25px;
}
.bottomBar {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.06);
  padding: 7px 60px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  z-index: 900;
}
.bottomBar.mobile {
  padding: 7px 20px;
  padding-left: 10px;
}
.floatingInfo {
  position: absolute;
  bottom: 60px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  box-shadow: 0 -4px 6px 1px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #000000;
  transition: 0.2s;
  overflow: hidden;
  /* border-radius: 10px; */
}
.floatingInfo .header {
  display: flex;
  color: #000000;
  padding: 8px 20px;
  justify-content: space-between;
  transition: 0.2s;
  background-color: #ffffff;
}
.floatingInfo.open .header {
  background-color: #f5f5f5;
  padding: 16px 20px;
}
.floatingInfo .icon {
  transition: 0.2s;
  margin-left: 5px;
}
.floatingInfo .icon.down {
  transform: rotate(180deg);
}
.floatingInfo .info {
  height: 250px;
  padding: 20px;
  overflow-y: scroll;
}
.selectionCount {
  font-size: var(--font-medium);
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.selectionCount span {
  padding-right: 5px;
}
.nextStepButton,
.prevStepButton {
  height: 46px;
  width: 150px;
  background-color: var(--primary-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  border: 0;
  font-size: var(--font-regualar);
  font-weight: 700;
  transition: 0.2s;
  outline: none;
  margin-left: 20px;
}
.prevStepButton {
  width: 90px;
  background-color: var(--accent-shade);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  margin-left: 10px;
  justify-content: center;
}
.prevStepButton:hover {
  background-color: #fff;
}
.nextStepButton.pc {
  width: auto;
  flex: 1;
  margin-left: 10px;
}
.nextStepButton.mobile {
  margin-left: 10px;
}
.nextStepButton:hover {
  background-color: var(--primary-color-d);
}
.nextStepButton:disabled {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff;
  cursor: not-allowed;
}

.topBarWrapper {
  height: 100%;
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
}

.barTextWithUnderline {
  font-size: var(--font-regualar);
  position: relative;
  align-items: center;
  display: flex;
  margin-left: 20px;
  font-weight: 700;
}
.footer {
  padding: 20px 0 20px 0;
  background-color: #f7f7f7;
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
}
.footerUrl {
  text-decoration: none;
  margin-left: 20px;
  font-size: var(--font-medium);
}
.footerUrl:first-of-type {
  margin-left: 0px;
}
.footerUrl:hover {
  text-shadow: 0 0 0px #000;
  border-bottom: 1px solid #000;
}
.footerUrl div {
  color: #000;
  text-shadow: 0 0 0px var(--primary-color);
  display: flex;
  align-items: flex-end;
}

/* booking type selection style */
.bookingTypeContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexRow {
  display: flex;
  margin: 0 0 32px;
  border-radius: 18px;
  border: 4px solid #49a6ba;
  background-color: #49a6ba;
  height: 78px;
}
.boxMainCon {
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 14px;
  padding: 40px 46px 30px;
}
.productTabLt {
  padding: 3px 5px 2px;
  border: 4px solid #55c2da;
  border-radius: 12px;
  display: flex;
  flex-wrap: nowrap;
  background-color: #55c2da;
  min-width: 500px;
  max-width: 600px;
  min-height: 55px;
}
.bookNowButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btnBookNow {
  font-size: 18px !important;
  padding: 16px 40px !important;
  border-radius: 14px !important;
  background-image: linear-gradient(
    92.88deg,
    #455eb5 9.16%,
    #5643cc 43.89%,
    #673fd7 64.72%
  );
  border-radius: 999px;
  box-shadow: #5e5df0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  min-width: 300px;
}
.btnBookNow:hover {
  border-radius: 14px !important;
}
.productSubHeading {
  font-size: 16;
  text-transform: none;
  padding-top: 5px;
}
.productHeading {
  font-size: 20px;
  font-weight: 600;
}
.productSubHeading {
  font-size: 14px;
}
@media screen and (max-width: 960px) {
  .center {
    justify-content: center !important;
    text-align: center;
  }
  .productTabLt {
    padding: 3px 5px 2px;
    border: 4px solid #55c2da;
    border-radius: 12px;
    display: flex;
    background-color: #55c2da;
    min-width: 500px;
    max-width: 800px;
    min-height: 80px;
  }
  .boxMainCon {
    padding: 25px 20px 25px;
  }
  .productHeading {
    font-size: 16px;
    font-weight: 600;
  }
  .productSubHeading {
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .bookingTypeContainer {
    height: auto;
    margin-top: 80px;
    padding: 20px;
  }
  .productTabLt {
    min-width: 440px;
    max-width: 550px;
    min-height: 55px;
  }
  .productHeading {
    font-size: 14px !important;
    font-weight: 600;
  }
  .productSubHeading {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 480px) {
  .bookingTypeContainer {
    height: auto;
    margin-top: 80px;
    padding: 20px;
  }
  .boxMainCon {
    padding: 25px 20px 25px;
  }
  .productTabLt {
    min-width: 200px !important;
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    min-height: 55px;
  }
  .appBody {
    margin-top: 0;
  }
  .productHeading {
    font-size: 12px !important;
    font-weight: 600;
  }
  .productSubHeading {
    font-size: 10px !important;
  }
}
.backButtonMB{
  display: none;
  margin-top: 1px;
}
@media (max-width: 1400px){
  .backButtonMB{
    display: block;
  }
}
@media (max-width: 960px){
  .backButtonMB{
    display: block;
    margin-top:70px;
  }
}
