.location-search {
  width: 100%;
  .address-picker-form {
    width: 100%;
  }
  .autocomplete-dropdown-container {
    position: absolute;
    max-width: 372px;
    z-index: 9999;
    width: 100%;
    word-break: break-all;
    font-size: 14px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.12),
      0px 3px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    margin-top: 2px;
    .suggestions {
      padding: 8px 16px;
    }
    .suggestions:hover {
      background-color: #6b6b6b !important;
      color: #ffffff;
    }
  }
}
.popover-address {
  .MuiPopover-paper {
    width: 28.8%;
    margin-left: -8rem !important;
    padding: 27px 16px;
  }
}
