
.addonField h3{
  text-align: center;
  margin-bottom: 20px;
  margin-top:0;
}
.addonContainer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.customAddonCard,.insuranceAddonCard{
  border-radius:10px;
  border:1px dashed transparent;
  min-height:280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:30px 15px 30px 15px;
  height: 100%;
}
.addonIcon{
  width:30px;
  height:30px;
  margin-bottom:20px;
}
.addonIcon img{
  width:100%;
  height:100%;
}
.addonInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space:initial;
  flex:1;
}
.addonInfo p{
  font-size: var(--font-medium);
  font-weight: 700;
  margin:6px 0;
  text-align: center;
}
.addonInfo span{
  text-align: center;
}
.addonRate{
  font-size: var(--font-semilarge);
  font-weight: 700;
  margin:15px 0;
  color:var(--primary-color);
}
.addCounter{
  position: relative;
}

.addonSelectButtonWrap{
  display: flex;
  align-items: center;
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
}
.insuranceSelectButtonWrap{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width:180px;
  height:34px;
}
.buttonStyledCheck:before{
  content:'Select';
  -webkit-appearance: none;
  background-color: #2A9CB7;
  width:180px;
  height:34px;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 8px;
  color:#fff;
  position: absolute;
  top:0;
  left:50%;
  margin-left:-90px;
  right:0;
  bottom:0;
  cursor:pointer;
}
.addonSelectCheck:checked + .buttonStyledCheck:before{
  content:'Remove';
  background-color: #fddfdc;
  color:#e00101;
  font-weight: initial;
}