.column {
  display: flex;
  flex-direction: column;
}

.filters {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: stretch;
}

.filters .button {
  display: flex;
  padding: 0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}

.chip {
  padding: 1px 4px;
  border-radius: 4px;
}

.filters .button.current,
.chip.current {
  color: rgba(0, 91, 227);
  background-color: rgba(0, 91, 227, 0.08);
}

.filters .button.upcoming,
.chip.upcoming {
  color: rgba(162, 94, 4);
  background-color: rgba(162, 94, 4, 0.08);
}

.filters .button.past,
.chip.past {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.filters .button.selected {
  border-color: rgba(0, 0, 0, 0.5);
}

.statusChip {
  padding: 2px 6px;
  border-radius: 4px;
}

.addDialog {
  width: 460px;
  padding: 25px
}

.profile {
  max-width: 680px;
  margin: auto;
}

.avatar {
  height: 70px;
  width: 70px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
}

.box {
  margin-top: 20px;
  border: 1px dashed #e9e9e9;
  border-radius: 10px;
  padding: 15px;
}

.card {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 5px;
  flex: 1
}

.dlImage {
  height: 56px;
  width: 90px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
}

.dlImage:first-child {
  margin-right: 4px;
}

.dlImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}