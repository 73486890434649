.column{
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}

.filters{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  gap: 0;
}
.filters .button{
  display: flex;
  padding:0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border:1px solid transparent;
  cursor: pointer;
}
@media (max-width: 768px){
  .filters{
    margin-top: 20px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0;
  }
}
@media (max-width: 500px){
  .filters{
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
  }
  .filters .button{
    margin-left: 0;
    margin-right: 6px;
  }
}
.chip{
  padding:1px 4px;
  border-radius: 4px;
}

.filters .button.current,.chip.current{
  color: rgba(0,91,227);
  background-color: rgba(0,91,227,0.08);
}
.filters .button.upcoming,.chip.upcoming{
  color: rgba(162,94,4);
  background-color: rgba(162,94,4,0.08);
}
.filters .button.past,.chip.past{
  color: rgba(180,0,0);
  background-color: rgba(180,0,0,0.08);
}
.filters .button.selected{
  border-color: rgba(0,0,0,0.5);
}

.statusChip{
  padding:2px 6px;
  border-radius: 4px;
}