.column {
  display: flex;
  flex-direction: column;
  padding-bottom: 130px;
}

.card {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 14px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 5px;
  flex: 1
}

.card.noBg {
  background-color: transparent;
  margin-top: 0;
}

.vehicleImage {
  width: 90px;
  height: 74px;
  overflow: hidden;
  border-radius: 6px;
  margin: -5px;
  margin-right: 12px;
}

.fuelIndicator {
  height: 0.6em;
  background-color: rgba(33, 153, 117, 0.1);
  border-radius: 0.5em;
  margin-top: 9px;
  position: relative;
}

.fuelIndicator div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(59, 199, 157);
  border-radius: 0.5em;
}

.box {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 20px;
}

.damageImages {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: center;
}

.damageImages .image {
  width: 80px;
  height: 56px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 2px;
  background-color: #f0f0f0;
}

.damageImages .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.damageImages .image .tag {
  position: absolute;
  top: 3px;
  left: 3px;
  display: flex;
  align-items: center;
  padding: 2px;
  padding-right: 8px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #fff;
}

.damageImages .image .tag p {
  margin: 0;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(187, 3, 3);
  color: #fff;
  font-weight: 700;
  margin-right: 3px;
}

.checkCard {
  padding: 12px 16px 16px 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: 100%;
  border: 1px solid transparent;
}

.checkCard.error {
  border-color: rgba(209, 49, 53, 0.5);
}

.checkCard.error .name {
  color: #D13135;
}

.odometerImage {
  width: 70px;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  margin-left: 5px;
  overflow: hidden;
  position: relative;
}

.odometerImage.empty .upload {
  opacity: 1;
}

.odometerImage .upload {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.2s;
}

.odometerImage:hover .upload {
  opacity: 1;
}