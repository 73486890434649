body {
  font-family: "Albert Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}
.subscription_pricing-overview {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
}
.next_step_btn {
  background: #000 !important;
  color: #fff !important;
  padding: 10px !important;
}
.next_step_btn:disabled {
  background-color: lightgrey !important;
}
.next_step_btn:hover {
  background: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
}
.vehicle_catalogue {
  background-color: transparent !important;
  position: relative;
}
.card_media_container {
  padding: 20px;
  border: 1px solid #585858;
  min-height: 300px;
  height: 100%;
  display: flex
;
  align-items: center;
}
.vehicle_catalogue_content {
  padding: 20px;
}
.vehicle_catalogue_selected {
  position: absolute;
}
.book_now_button {
  width: 100% !important;
  background-color: #C1C1C1 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  border-radius: 0px !important;
}
.book_now_button:hover {
  background-color: #D9D9D9 !important;
}
.plan_inclusion_list {
  padding-left: 20px;
  font-weight: 600;
  font-weight: 14px;
}
.plan_inclusion_list li {
  font-size: 14px;
  padding: 5px;
}
/* dvla licence check styles */
.licence_check_container {
  display: flex;
  align-items: center;
}
.dvla_main_container {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 30px;
  flex-grow: 1;
}

.included_plan_services {
  border-radius: 10px;
  min-height: 150px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 15px 15px;
  height: 100%;
  justify-content: space-between;
}
.included_service_icon {
  width: 30px;
  height: 30px;
}

.subscription_input_style {
  border-radius: 0px !important;
  line-height: 1.3rem !important;
}