.cardLeft{
  position: absolute;
  top:110px;
  left:20px;
  background-color: #fff;
  border-radius: 10px;
  padding:15px;
  padding-top:5px;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 4px 10px 1px rgba(0,0,0,0.1);
}

.cardLeft h2{
  font-size: 14px;
  margin-top:13px;
  margin-bottom: 5px;
}

.cardRight{
  position: absolute;
  top:110px;
  right:20px;
  bottom:20px;
  width:280px;
  display: flex;
  flex-direction: column;
}
.vehicleInfoBox{
  background-color: #fff;
  box-shadow: 0 0 30px 1px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 13px;
  padding:5px;
}
.mobiliseButton{
  background: #c54740 !important;
  font-weight: 600 !important;
  width: 100% !important;
  border-radius: 10px;
  transition: 0.2s;
}
.mobiliseButton:hover{
  opacity: 0.9;
}
.legendBox{
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 30px 1px rgba(0,0,0,0.1);
  padding:0 10px;
}

.vehicleImage{
  width:100px;
  height:70px;
  background:#f9f9f9;
  margin:10px 0 10px 10px;
  border-radius: 6px;
  overflow: hidden;
}
.vehicleImage img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.label{
  margin:3px 0;
  font-size: 12px;
  color:#616161;
}
.coloredInfoCard{
  display: flex;
  justify-content: center;
  flex:1;
  margin-right:10px;
  height:70px;
  background-color: #646464;
  border-radius: 8px;
  flex-direction: column;
  padding:10px;
  color:#fff;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 14px;
}
.coloredInfoCard.teal{
  background-color: #00AA9D;
}
.coloredInfoCard.blue{
  background-color: #286CD9;
}
.coloredInfoCard.orange{
  background-color: #EA8133;
}
.coloredInfoCard .label{
  color:#fff;
  margin-bottom:6px;
  font-weight: initial;
  font-size: 12px;
}
.legend{
  width:50%;
  font-size: 13px;
  color:#000000;
  box-sizing: border-box;
  margin:0;
  position: relative;
  cursor: pointer;
  display: flex;
  padding:4px 0;
  border-radius: 4px;
  transition: 0.2s;
  border:1px dashed transparent
}
.legend span{
  display: inline-flex;
  align-items: center;
  margin-left:5px;
}
.legend.active{
  font-weight: 700;
  border-color: #000;
}