.column {
  display: flex;
  flex-direction: column;
  padding: 130px 0px 30px 50px;
  overflow: auto;
}
.tableContainer { 
  width: 100%; 
  padding-top: 10px;
  margin-top: 10px;
}
.filterContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
@media only screen and (max-width:425px) {
  .filterContainer{
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}
.filters {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: stretch;
}

.filters .button {
  display: flex;
  padding: 0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}

.chip {
  padding: 1px 4px;
  border-radius: 4px;
}

.filters .button.unpaid,
.chip.unpaid {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.filters .button.paid,
.chip.paid {
  color: rgb(0, 107, 80);
  background-color: rgba(0, 107, 80, 0.1);
}

.filters .button.past,
.chip.past {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.filters .button.selected {
  border-color: rgba(0, 0, 0, 0.5);
}

.statusChip {
  padding: 2px 6px;
  border-radius: 4px;
}

.thumbnail {
  width: 80px;
  height: 50px;
  border-radius: 6px;
  overflow: hidden;
  margin: -5px 0
}

.thumbnail img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.shade {
  border: 1px dashed #d8d8d8;
  padding: 6px;
  border-radius: 6px;
  margin: 1px
}

.shade.item {
  border: 0;
  background-color: #f0f0f0;
  padding: 10px 10px;
  margin: 2px 0;
  cursor: pointer;
}

.shade.item.red {
  background-color: rgba(231, 0, 0, 0.122);
  color: rgb(166, 28, 28);
  margin-bottom: 10px;
}

.shade.item.red.selected {
  background-color: #a83030;
  color: #fff
}

.shade.item:hover {
  background-color: #e9e9e9;
}

.shade.item.selected {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 700;
  margin: 4px 0;
}

.driverButton {
  align-self: center;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px 6px;
  margin: 0 -6px
}

.driverButton p {
  margin: 0
}

.driverButton:hover {
  background-color: #e2e2e2;
}

.textButton {
  display: inline-flex;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: 1px solid;
}