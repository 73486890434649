@media print {
  body {
    overflow: visible;
  }
  .break {
    break-after: page;
    top: 0px;
  }
}

@page {
  size: auto;
  margin-bottom: 20mm;
}

.loading-message{
  margin-top: 150px;
}
