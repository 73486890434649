
.paymentDialog{
  padding:20px 30px 40px 30px;
  min-width: 600px;
  position: relative;
}
@media screen and (max-width: 600px){
  .paymentDialog.mobile{
    min-width: 0;
    padding:20px 20px 40px 20px;
  }
}
.payAmoutButton{
  padding:24px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top:5px;
  transition: 0.2s;
  color:#000000
}

.payAmoutButton:hover{
  background-color: #f0f0f0;
}

.payAmoutButton.full{
  background-color: var(--accent-shade);
}
.payAmoutButton:hover,.payAmoutButton.full:hover{
  background-color: var(--accent-shade-d);
}
.closeDialog{
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -5px;
}

.stripeContainer{
  width:40%;
  margin: 0 auto;
}
@media screen and (max-width: 600px){
  .stripeContainer{
    width:100%;
  }
}