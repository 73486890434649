@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* mini slider style for vehicle group images */

.slider-mini-vg .slick-arrow {
  z-index: 4;
}
.slider-mini-vg .slick-arrow.slick-prev {
  left: 0rem;
}
.slider-mini-vg .slick-arrow.slick-next {
  right: 0rem;
}
.slider-mini-vg .slick-dots li {
  width: 10px;
  margin: 0;
}
.slider-mini-vg .slick-dots li button, .slider-mini-vg .slick-dots li button::before  {
  width: 10px;
}
.carauselContainer {
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.field-error-message {
  color: #d13135;
  font-size: 0.75rem;
  margin-top: 3px;
  margin-left: 12px;
  display: block;
  font-weight: 400;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* language button style */
#localize-widget {
  z-index: 1200 !important;
}
#localize-widget.localize-right-top {
  right: 30% !important;
  top: 1.8% !important;
  border-radius: 10px !important;
}

#localize-active-lang {
  font-size: 12px !important;
  text-decoration: none;
  text-align: center!important;
  height: 37px !important;
  line-height: 36px !important;
  font-weight: normal!important;
  padding: 0 12px !important;
  display: block;
  font-weight: 600 !important;
  border-radius: 12px;
}

#localize-active-lang, #localize-widget-langs a {
  font-size: 12px !important;
  max-width: 250px !important;
  min-width: 80px !important;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  box-sizing: border-box;
}

@media only screen and (max-width: 1600px) {
  #localize-widget.localize-right-top {
    right: 20% !important;
  }
}

@media only screen and (max-width: 1400px) {
  #localize-widget.localize-right-top {
    right: 20% !important;
  }
}

@media only screen and (max-width: 1200px) {
  #localize-widget.localize-right-top {
    right: 20% !important;
  }
}
@media only screen and (max-width: 900px) {
  #localize-widget.localize-right-top {
    right: 25% !important;
  }
  #localize-active-lang {
    font-size: 12px !important;
    height: 35px !important;
    line-height: 35px !important;
    font-weight: normal!important;
    padding: 0 5px !important;
    display: block;
    font-weight: 600 !important;
    border-radius: 12px;
  }
  #localize-active-lang, #localize-widget-langs a {
    font-size: 12px !important;
    max-width: 250px !important;
    min-width: 80px !important;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 600px) {
  #localize-widget.localize-right-top {
    right: 25% !important;
  }
}

@media only screen and (max-width: 480px) {
  #localize-widget.localize-right-top {
    display: none !important;
  }
}






