.rightCol{
  width:100%;
  display: flex;
  flex-direction: column;
  flex:1;
  margin: 130px 0px 50px 0px;
  position: relative;
}

@media only screen and (min-width: 600px) {
  .rightCol{
    margin-left: 200px;
  }
}
@media only screen and (min-width: 1280px) {
  .rightCol{
    margin-left: 250px;
  }
}