.item{
  background-color: #f9f9f9;
  border-radius: 6px;
  padding:6px 6px;
  margin-bottom: 5px;
}

:root {
  --space: 150px;
  --space-xs: calc(var(--space) / 3);
  --space-sm: calc(var(--space) / 2);
  --space-md: calc(var(--space) * 2);
}

.sidebar {
  --offset: var(--space);
  position: sticky;
  top: var(--offset);
  max-height: calc(100vh - var(--offset) * 2);
}

@media screen and (max-width: 960px){
  .sidebar{
    max-height: 650px;
  }
}

