@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: "Albert Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
}

code {
  font-family: "Albert Sans", sans-serif !important;
}

/* this is a error fallback css  */
.cross-center{
  align-items: center;
}
.col-flex{
  flex-direction: column;
}
.error-message{
  margin:-50px -40px;
  background-color: #fff;
  min-height: 100vh;
  padding-top:100px;
  align-self: stretch;
}
.react-error-image{
  width:350px;
  height: auto;
}
.react-error-image img{
  width: 100%;
  height:auto;
}
.error-action{
  margin-top: 40px;
}
.valetCard-remove-btn {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 180px;
  height: 34px;
  border-radius: 10px;
  cursor: pointer;
  margin: 25px 0px 0px 0px;  
}