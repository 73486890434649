.slickySlider {
  width: 100%;
}
.slickySlider .slick-arrow {
  z-index: 4;
}
.slickySlider .slick-arrow.slick-prev {
  left: 2rem;
}
.slickySlider .slick-arrow.slick-next {
  right: 2rem;
}
.slickySlider .slick-arrow.slick-prev::before {
  font-size: 2rem;
}
.slickySlider .slick-arrow.slick-next::before {
  font-size: 2rem;
}
.carauselContainer {
  width: 100%;
}
.imageContainer {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
}
.imageContainer .image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
