.column {
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}

.card {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 5px;
  flex: 1
}

.vehicleImage {
  width: 90px;
  height: 74px;
  overflow: hidden;
  border-radius: 6px;
  margin: -5px;
  margin-right: 12px;
}

.fuelIndicator {
  height: 0.6em;
  background-color: rgba(33, 153, 117, 0.1);
  border-radius: 0.5em;
  margin-top: 9px;
  position: relative;
}

.fuelIndicator div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(59, 199, 157);
  border-radius: 0.5em;
}

.box {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1px;

}

.damageImages {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: center;
}

.damageImages .image {
  width: 80px;
  height: 56px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 2px;
}

.damageImages .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.damageImages .image .tag {
  position: absolute;
  top: 3px;
  left: 3px;
  display: flex;
  align-items: center;
  padding: 2px;
  padding-right: 8px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #fff;
}

.damageImages .image .tag p {
  margin: 0;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(187, 3, 3);
  color: #fff;
  font-weight: 700;
  margin-right: 3px;
}

.checkCard {
  padding: 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: 100%;
}

.logo {
  height: 76px;
  width: auto;
}

.logo img {
  height: 100%;
  width: auto;
}

/* swap ui css */
.swapDetailsBox {
  display: flex;
  padding: 20px 0px 0px 20px;
  justify-content: space-between;
}

.swapNoteBox {
  background: #ececec;
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.updatesLoader {
  padding-left: 10px;
}
.header{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.headerButtons{
  display: flex;
  flex:1;
  justify-content: flex-end;
}
@media (max-width: 450px) {
  .header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .headerButtons{
    display: flex;
    flex:1;
  }
}