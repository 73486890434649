.flatpickr-container {
  width: 100%;
  display: flex;
  .flatpickr-wrapper {
    margin-right: 0px !important;
  }
  .flatpickr-input {
    min-height: 38px;
    font-size: 14px;
    width: 100%;
    min-width: 162px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 14px;
    outline: none;
  }
  .flatpickr-input:focus {
    border: 2px solid #FFC700;
  }

  .flatpickr-wrapper {
    width: 100%;
    display: block !important;
    min-width: 150px;
    margin-right: 5px !important;
  }

  img.datePickerClearIcon {
    left: -30px;
    top: 12px;
    position: relative;
    cursor: pointer;
    width: 12px;
  }
}

.fleet-calendar-container {
  .fleet-calendar {
    cursor: pointer;
    width: 56px;
    color: #fff;
    height: 42px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
    outline: none;
    padding: 12px 14px 0px;
  }
}
