.container {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
}

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: var(--theme-accent);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 52px;
}

.body {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 60px;
  overflow-y: auto;
  background-color: #fff;
}

.card {
  max-width: 400px;
  margin: auto;
}

.card .top,
.card .bottom {
  padding: 10px 30px 20px 30px;
  background-color: var(--accent-shade-light);
  border-radius: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.card .top {
  display: flex;
  align-items: center;
}

.card .bottom {
  padding-top: 26px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 4px;
}

.card p {
  font-size: 14px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.errorText {
  color: #c71717;
  float: right;
  margin-left: 10px;
  font-weight: initial;
}

.errorText.left {
  float: initial;
  margin: 4px 0;
  font-size: 14px;
}

.errorBox,
.infoBox {
  background: #ffe0e0;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  border: 1px solid #bf4848;
  color: #bf4848;
  font-size: 14px;
}

.infoBox {
  background-color: #eee4ca;
  border-color: #c7b294;
  color: #a36200;
}

.payAmoutButton {
  padding: 14px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 5px;
  transition: 0.2s;
  color: #000000;
  min-width: 300px;
}

.payAmoutButton:hover {
  background-color: #f0f0f0;
}

.payAmoutButton.full {
  background-color: var(--accent-shade);
}

.payAmoutButton:hover,
.payAmoutButton.full:hover {
  background-color: var(--accent-shade-d);
}

.valid{
  font: "Times New Roman";
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-small;
  color:green;
  margin-left:30px;
}
.valid:before {
  position: relative;
  left: -35px;
  content: "✔";
}

.invalid { 
  font: "Times New Roman";
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-small;
  color:red;
  margin-left: 30px;
}
.invalid:before {
  position: relative;
  left: -35px;
  content: "✖";
}

.bottomLinks{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.bottomLinks p{
  color: var(--theme-accent);
  margin: 0 0 10px 0;
  cursor: pointer;
}
@media (max-width: 400px) {
  .bottomLinks {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .bottomLinks p {
    margin: 0;
    margin-bottom: 10px;
  }
}