.flatpickr-container {
  width: 100%;
  position: relative;
  .flatpickr-wrapper {
    margin-right: 0px !important;
  }
  .flatpicker-date-label {
    position: absolute;
    display: block;
    top: -8px;
    z-index: 1;
    left: 8px;
    background: #fff;
    padding: 0px 5px;
    font-size: 10px;
    .flat-picker-required {
      position: relative;
      top: 2px;
      left: 2px;
    }
  }
  .flat-picker-error {
    color: #d13135;
    margin-left: 14px;
    margin-right: 14px;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
  .flatpickr-input {
    min-height: 38px;
    font-size: 14px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    padding-left: 12px;
  }
  .flatpickr-input:focus {
    border: 2px solid var(--theme-primary);
  }

  .flatpickr-wrapper {
    width: 100%;
    display: block !important;
  }

  img.datePickerClearIcon {
    left: -30px;
    top: 12px;
    position: relative;
    cursor: pointer;
    width: 12px;
  }
}
.simple-time-picker {
  .flatpickr-wrapper {
    .flatpickr-input {
      width: 90%;
    }
  }
}
.date-time-picker {
  .flatpickr-wrapper {
    min-width: 0px !important;
    .flatpickr-input {
      min-width: 0px;
    }
  }
}

.fleet-calendar-container {
  .fleet-calendar {
    cursor: pointer;
    width: 56px;
    color: #fff;
    height: 42px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
    outline: none;
    padding: 12px 14px 0px;
  }
}
.flatpickr-container.required-date {
  .flatpicker-date-label {
    top: -9px;
  }
}

.replacement-reason-date-time {
  .flatpickr-input {
    box-sizing: border-box;
    padding-left: 10px;
  }
}

.MuiPaper-root.MuiMenu-paper {
  max-height: 400px;
}