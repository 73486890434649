.appContainer{
  display:flex;
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  flex-direction: column;
  align-items: stretch;
}
.topBar{
  height:90px;
  background-color: var(--brand-accent);
  border-bottom:1px solid var(--top-bar-border);
  position: fixed;
  top:0;
  left:0;
  right:0;
  display: flex;
  z-index: 999;
  align-items: stretch;
  transition: 0.2s;
}

.topBarWrapper{
  height:100%;
  display: flex;
  flex:1;
  align-self: stretch;
  align-items: center;
}
.topBarLogo{
  min-width:40px;
  height: 50%;
  display: flex;
  align-items: center;
}
.topBarLogo img{
  height:auto;
  width:auto;
  max-height: 100%;
}
.topBarLogo p{
  color:var(--brand-accent);
  font-size: 22px;
}

.topBarTitle {
  font-weight: 700;
  max-width: 85%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: calc(1.5em * 3);
  -webkit-line-clamp: 3;
}
@media (max-width: 450px){
  .topBarTitle{
    max-width: 100%;
  }
}
.topBarButtonTitle{
  font-weight: 700 !important;
  white-space: nowrap;
}
@media (max-width: 360px){
  .topBarButtonTitle{
    white-space: wrap;
    margin-left: 10px;
  }
}

.barTextWithUnderline{
  font-size: var(--font-regualar);
  position: relative;
  align-items: center;
  display: flex;
  margin-left: 20px;
  font-weight: 700;
}
.footer{
  height: 60px;
  background-color: #f7f7f7;
  display: flex;
}
.footerUrl{
  text-decoration: none;
  margin-right: -20px;
  margin-left: 40px;
  font-size: var(--font-medium);
}
.footerUrl:hover{
  text-shadow:0 0 0px #000;
  border-bottom: 1px solid #000;
}
.footerUrl div{
  color:#000;
  text-shadow:0 0 0px var(--primary-color);
  display: flex;
  align-items: flex-end;
}
.auth{
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 0px;
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  color: #000;
  font-size: 13px;
  cursor: pointer;
}

.iconWrap{
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.authDialog{
  padding: 40px;
}
@media (max-width: 400px){
  .authDialog{
    padding: 20px;
  }
}