.carouselModule{
  padding:50px 0;
  padding-bottom: 80px;
  background-color:#fff;
}
.sectionHeaderLine{
  width:30px;
  height:4px;
  border-radius: 2px;
  background-color: var(--primary-color);
}
.carauselContainer{
  
}
.slider{
  border-radius: 10px;
  overflow:hidden;
}
.imageGrid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
}
.imageContainer,.gridImage{
  width:auto;
  outline: none;
  padding:0 3px;
  position: relative;
  margin-bottom: 6px;
}

.imageContainer:last-child{
  margin-bottom: 0;
}
.gridImage{
  display: flex;
  flex: 0 0 33.333333%;
  margin: 3px 0;
}
.image{
  height: auto;
  width:100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
}
.image img{
  height:100%;
  width:100%;
  object-fit: cover;
}
.arrowCircle{
  height:30px;
  width:30px;
  border:2px solid #e2e2e2;
  color:#848484;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items:center;
}