.button{
    height:46px;
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
    width:130px;
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    margin-right: 20px;
}
.button:hover{
    background-color: #fff;
}