html{
  font-size: 16px;
}
*{
  box-sizing: border-box;
}
:root{
  --brand-accent:rgba(116, 192, 68,1);
  --accent-shade:rgba(0, 154, 78,0.1);
  --accent-shade-d:rgba(0, 154, 78,0.2);
  --accent-shade-light:rgba(116, 192, 68,0.05);
  --primary-color:rgb(0, 154, 78);
  --primary-color-d:rgba(0, 154, 78,0.9);
  --stepper-color:rgba(0,0,0,0.5);
  --top-bar-border:var(--accent-shade);
  --shadow-color:#eeeeee;
  --contrast5:#f9f9f9;
  --border-medium:#e0e0e0;
  --border-dark:#c0c0c0;
  --border-light:#e6e6e6;
  --background-secondary:#f6f6f6;
  --background-primary:#fff;
  --font-regular:1rem;
  --font-medium:0.88rem;
  --font-small:0.82rem;
  --font-semilarge:1.1rem;
}
.row-hover tr{
  cursor: pointer;
}
.row-hover tr:hover{
  background-color: rgba(0,0,0,0.05);
}
.pointer{
  cursor:pointer
}
.hover-opacity{
  transition: 0.2s;
}
.hover-opacity:hover{
  opacity:0.7
}
.opacity40{
  opacity:0.4
}
.opacity50{
  opacity:0.5
}
.opacity60{
  opacity:0.6
}
.img-cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text-center{
  text-align: center;
}
.text-grey{
  color:#848484
}
.text-error{
  color:#D13135
}
.text-accent{
  color:var(--primary-color) !important;
}
.underline{
  text-decoration: underline;
}
.font-regular{
  font-size: var(--font-regular);
}
.font-medium{
  font-size: var(--font-medium);
}
.font-small{
  font-size: var(--font-small);
}
.small-icon{
 font-size: 15px;
}
.bold{
  font-weight: 700 !important;
}
.semi-bold{
  font-weight: 500 !important;
}
.capitalize{
  text-transform: capitalize;
}
.flex{
  display: flex;
}
.wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.no-margin{
  margin:0
}
.padding-regular {
    padding: 10px;
}
.padding-top{
  padding-top:10px;
}
.padding-left{
  padding-left:10px;
}
.padding-left--small{
  padding-left:5px;
}
.padding-bottom--small{
  padding-bottom:3px;
}
.padding-bottom{
  padding-bottom:10px;
}
.padding-bottom--l{
  padding-bottom:16px;
}
.padding-right{
  padding-right:10px;
}
.margin-top{
  margin-top:10px;
}
.margin-left{
  margin-left:10px;
}
.margin-bottom{
  margin-bottom:10px;
}
.margin-right{
  margin-right:10px;
}
.fill{
  flex:1;
}
.col-flex{
  flex-direction: column;
}
.space-between{
  justify-content: space-between;
}
.main-center{
  justify-content: center;
}
.cross-center{
  align-items: center;
}
.cross-baseline{
  align-items: baseline;
}
.main-end{
  justify-content: flex-end;
}
.cross-start{
  align-items: flex-start;
}
.cross-end{
  align-items: flex-end;
}
.position-relative{
  position: relative;
}

.basic-details-datepicker-input:hover,.basic-details-datepicker-input:focus{
  background-color: transparent;
}
.MuiInput-underline:before,.MuiInput-underline:after{
  display:none
}
.MuiInput-underline{
  cursor: pointer;
}
.carousel-prev,.carousel-next{
  width:50px !important;
  background-color: rgba(255,255,255,0.4) !important;
  border-radius: 6px !important;
}
.carousel-prev:hover,.carousel-next:hover{
  background-color: rgba(255,255,255,0.7) !important;
}
.carousel-prev:before,.carousel-next:before{
  height:30px !important;
  width:30px !important;
  opacity:1 !important;
}
.carousel-row div{
  outline: none !important;
}
.carousel-next:before{
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='768' height='768' viewBox='0 0 768 768'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M736 384c0-97.184-39.424-185.248-103.104-248.896s-151.712-103.104-248.896-103.104-185.248 39.424-248.896 103.104-103.104 151.712-103.104 248.896 39.424 185.248 103.104 248.896 151.712 103.104 248.896 103.104 185.248-39.424 248.896-103.104 103.104-151.712 103.104-248.896zM672 384c0 79.552-32.192 151.488-84.352 203.648s-124.096 84.352-203.648 84.352-151.488-32.192-203.648-84.352-84.352-124.096-84.352-203.648 32.192-151.488 84.352-203.648 124.096-84.352 203.648-84.352 151.488 32.192 203.648 84.352 84.352 124.096 84.352 203.648zM256 416h178.752l-73.376 73.376c-12.512 12.512-12.512 32.768 0 45.248s32.768 12.512 45.248 0l128-128c2.944-2.944 5.312-6.464 6.944-10.368 3.232-7.84 3.232-16.672 0-24.512-1.568-3.776-3.872-7.296-6.944-10.368l-128-128c-12.512-12.512-32.768-12.512-45.248 0s-12.512 32.768 0 45.248l73.376 73.376h-178.752c-17.664 0-32 14.336-32 32s14.336 32 32 32z'%3E%3C/path%3E%3C/svg%3E") !important;
}
.carousel-prev:before{
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='768' height='768' viewBox='0 0 768 768'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M736 384c0-97.184-39.424-185.248-103.104-248.896s-151.712-103.104-248.896-103.104-185.248 39.424-248.896 103.104-103.104 151.712-103.104 248.896 39.424 185.248 103.104 248.896 151.712 103.104 248.896 103.104 185.248-39.424 248.896-103.104 103.104-151.712 103.104-248.896zM672 384c0 79.552-32.192 151.488-84.352 203.648s-124.096 84.352-203.648 84.352-151.488-32.192-203.648-84.352-84.352-124.096-84.352-203.648 32.192-151.488 84.352-203.648 124.096-84.352 203.648-84.352 151.488 32.192 203.648 84.352 84.352 124.096 84.352 203.648zM512 352h-178.752l73.376-73.376c12.512-12.512 12.512-32.768 0-45.248s-32.768-12.512-45.248 0l-128 128c-3.072 3.072-5.376 6.592-6.944 10.368-1.632 3.904-2.432 8.096-2.432 12.256 0 8.192 3.136 16.384 9.376 22.624l128 128c12.512 12.512 32.768 12.512 45.248 0s12.512-32.768 0-45.248l-73.376-73.376h178.752c17.664 0 32-14.336 32-32s-14.336-32-32-32z'%3E%3C/path%3E%3C/svg%3E") !important;
}


/* flatpickr */

.flatpickr-day.selected{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.flatpickr-months .flatpickr-month,.flatpickr-monthDropdown-months{
  background-color: var(--primary-color) !important;
}
.flatpickr-weekdays{
  background-color: var(--primary-color) !important;
}
span.flatpickr-weekday{
  background: transparent !important;
  color:#fff !important;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month{
  padding:7px 10px !important
}
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover{
  color:#fff !important;
  fill:#fff !important;
  opacity: 0.8;
}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg{
  color:#fff !important;
  fill:#fff !important;
}
.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before {
  border-bottom-color:var(--primary-color) !important;
}
.flatpickr-monthDropdown-months{
  -webkit-appearance: menulist-textfield !important;
}
  .PhoneInputCountry{
    /* border:1px solid #e2e2e2 !important; */
    border-radius: 8px !important;
    padding:0 10px 0 15px !important;
    font-size: var(--font-medium) !important;
    background-color: #f0f0f0 !important;
  }
  .PhoneInputInput{
    padding:14px !important;
    border:0 !important;
    border-radius: 8px !important;
    font-size: var(--font-medium) !important;
  }
  .PhoneInputInput:hover{
    border-color: rgba(0, 0, 0, 0.87) !important;
  }
  .PhoneInputInput:focus{
    border:0 !important;
    outline: none !important;
  }
  .PhoneInputInputError .PhoneInputInput{
    border-color: #D13135 !important;
  }