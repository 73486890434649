.actionField{
  background-color: #f1f3f4;
  min-height:100vh;
  padding-top:100px;
  padding-bottom: 100px;
}
.header{
  margin:0px 0px 30px 0px;
  text-align: center;

}
.quoteBody{
  background-color: #fff;
  border-radius: 5px;
  padding:50px 30px 50px 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 30px 1px #e9ecee;
}

@media screen and (max-width: 600px){
  .quoteBody{
    padding:30px 15px 30px 15px;
  }
}

.bottomBar{
    height:60px;
    background-color:#fff;
    box-shadow: 0 0 20px 1px rgba(0,0,0,0.06);
    padding:8px 20px;
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    flex:1;
    overflow: hidden;
    justify-content: center;
    z-index: 900;
}
.nextStepButton,.prevStepButton{
    height:46px;
    width:150px;
    background-color: var(--primary-color);
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color:#fff;
    border:0;
    font-size: var(--font-regualar);
    font-weight: 700;
    transition: 0.2s;
    outline: none;
}
.prevStepButton{
  width:90px;
    background-color: var(--accent-shade);
    color:var(--primary-color);
    border:1px solid var(--primary-color);
    margin-right: 20px;
}
.prevStepButton:hover{
    background-color: #fff;
}
.nextStepButton:hover{
    background-color: var(--primary-color-d);
}
.nextStepButton:disabled{
    background-color: rgba(0,0,0,0.15);
    color:#fff;
    cursor:not-allowed
}

.greyButton,.whiteButton{
  width:90px;
  background-color: rgba(0,0,0,0.15);
    color:#fff;
    border:1px solid rgba(255,255,255,0.35);
  margin-right: 10px;
  justify-content: center;
}
.whiteButton{
  background-color: #fff;
  color:var(--brand-accent);
  margin-right:25px; 
}
.greyButton{
  width:160px;
}
.greyButton:hover{
  background-color: rgba(255,255,255,0.05);
}

.paymentDialog{
  padding:20px 30px 40px 30px;
  min-width: 600px;
  position: relative;
}

@media screen and (max-width: 600px){
  .paymentDialog.mobile{
    padding:0.5rem;
    min-width: 0;
    overflow-x: hidden;
  }
}
.payAmoutButton{
  padding:24px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top:5px;
  transition: 0.2s;
  color:#000000
}

.payAmoutButton:hover{
  background-color: #f0f0f0;
}

.payAmoutButton.full{
  background-color: var(--accent-shade);
}
.payAmoutButton:hover,.payAmoutButton.full:hover{
  background-color: var(--accent-shade-d);
}
.closeDialog{
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -5px;
}
