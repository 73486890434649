.pageModule{
  min-height: calc(100vh - 240px);
  background-color: #fff;
}
.moduleWrap{
  padding:0;
  text-align: center;
  background-color: #fff;
  /* border-bottom: 1px solid #e7e7e7; */
}

.diffModule {
  padding: 0;
  background-color:#fff;
}

@media (min-width: 768px) {
  .moduleWrap{
    padding:60px 0 20px 0;
    text-align: center;
    background-color: #fff;
    /* border-bottom: 1px solid #e7e7e7; */
  }
  
  .diffModule {
    padding: 40px 15px 80px 30px;
    background-color:#fff;
  }
}