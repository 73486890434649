.column{
  width:200px;
  position: fixed;
  top:0;
  bottom: 0;
  left:0.0625rem;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  padding-top: 126px;
  z-index: 10;
}
.menuIcon{
  background-color: var(--primary-color);
  box-shadow: 0 0 3px gray;
  margin-right: 10px;
  border-radius: 25%;
}
@media only screen and (min-width: 1280px) {
  .column{
    left:calc(50% - 610px);
  }
}

.navItem{
  display: flex;
  align-items: center;
  padding:14px 16px;
  border-bottom: 1px solid #eeeeee;
  font-size: 18px;
  cursor: pointer;
  border-left: 4px solid transparent;
  text-decoration: none;
  color: initial;
  color: #292929;
}

.navItem.active{
  border-left-color: var(--primary-color);
  color: var(--primary-color);
}

.draweHeader{
  width: 0.75rem;
}

.navItem span{
  font-weight: 500;
  margin-left: 8px;
  font-size: 14px;
}