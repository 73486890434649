.eventMarker{
  transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  height:12px;
  width:12px;
  cursor:pointer;
  position: relative;
}
.infoPoper{
  position: absolute;
  bottom: 30px;
  left:6px;
  width:130px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px 1px rgba(0,0,0,0.1);
  transform: translateX(-50%);
  -moz-transform:translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 1000;
  padding:10px;
}
.infoPoper::after{
  content: "";
  position: absolute;
  width:16px;
  height: 16px;
  background-color: #fff;
  transform: rotate(45deg) translateX(-8px);
  border-radius: 3px;
  bottom: -10px;
  left:50%;
}
.infoPoper h3{
  margin:0; 
}
.infoPoper p{
  margin:3px 0; 
  display: flex;
  align-items: center;
  font-size: 12px;
}
.endPointMarker{
  background-color: #fff;
  padding: 4px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 0px 10px 1px rgba(0,0,0,0.25);
  width: 36px;
  height: 20px;
  transform: translateX(-22px);
}
.endPointMarker div{
  height: 20px;
  width: 36px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  border-radius: 5px; 
  color: #fff;
  font-size: 12px; 
  font-weight: 700 ;
  position: relative;
}
.endPointMarker::before{
  content: "";
  position: absolute;
  width:12px;
  height: 12px;
  background-color: #fff;
  transform: rotate(45deg) translateX(-8px);
  border-radius: 3px;
  top: 0px;
  left:50%;
}
