.bottomSheet{
    height:50vh;
    width:100%;
}
.bottomSheetHeader{
    padding: 1rem 0.75rem;
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.bottomSheetSelection{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    height: calc(100% - 3.563rem);
    border-top: rgb(188, 188, 188) solid 1px;
}
.bottomSheetFilterList{
    width:150px;
    height:100%;
    overflow-y: auto;
}
.bottomSheetFilter{
    padding: 1rem;
    border-bottom: 1px solid rgb(227, 227, 227);
    border-right: 1px solid rgb(227, 227, 227);
    background-color: rgb(255, 255, 255);
    text-align: center;
}
.bottomSheetFilter.active{
    background-color:rgb(227, 227, 227);
    color: var(--brand-accent);
}
.filterSelectionWrapper{
    padding: 2rem;
    width:100%;
    height:100%;
    overflow-y: auto;
}
.filterSelection{
    display:flex;
    justify-content: flex-start;
    align-items:center;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 10px;
}
.singleFilter{
    padding: 0.45rem 0.35rem;
    min-width: 100px;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
    text-align: center;
    background-color: transparent;
    word-break: break-word;
}
.singleFilter.activeFilter{
    background-color: rgb(223, 222, 222);
}