.column {
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
}
.headerContainer{
  display: flex;
  flex:1;
  align-items: center;
}
.headBalContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filters {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  gap: 0;
}
@media (max-width: 1046px){
  .headerContainer{
    display: block;
  }
  .filters{
    justify-content: flex-start;
    margin-top: 10px;
  }
}
.filters .button {
  display: flex;
  padding: 0 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}
@media (max-width: 768px){
  .filters{
    margin-top: 20px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0;
  }
}
@media (max-width: 500px){
  .filters{
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
  }
  .filters .button{
    margin-left: 0;
    margin-right: 6px;
  }
}
.chip {
  padding: 1px 4px;
  border-radius: 4px;
}

.filters .button.unpaid,
.chip.unpaid {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.filters .button.paid,
.chip.paid {
  color: rgb(0, 107, 80);
  background-color: rgba(0, 107, 80, 0.1);
}

.filters .button.past,
.chip.past {
  color: rgba(180, 0, 0);
  background-color: rgba(180, 0, 0, 0.08);
}

.filters .button.selected {
  border-color: rgba(0, 0, 0, 0.5);
}

.statusChip {
  padding: 2px 6px;
  border-radius: 4px;
}

.card {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 5px;
  flex: 1
}

.box {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  margin-top: -1px;

}

.paymentDialog {
  padding: 20px 30px 40px 30px;
  min-width: 600px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .paymentDialog.mobile {
    padding: 20px 20px 40px 20px;
  }
}

.payAmoutButton {
  padding: 24px 15px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top: 5px;
  transition: 0.2s;
  color: #000000
}

.payAmoutButton:hover {
  background-color: #f0f0f0;
}

.payAmoutButton.full {
  background-color: var(--accent-shade);
}

.payAmoutButton:hover,
.payAmoutButton.full:hover {
  background-color: var(--accent-shade-d);
}

.closeDialog {
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -5px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  align-items: center;
}