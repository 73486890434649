.profile {
  max-width: 680px;
  margin: auto;
}

.avatar {
  height: 70px;
  width: 70px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
}

.box {
  margin-top: 20px;
  border: 1px dashed #e9e9e9;
  border-radius: 10px;
  padding: 15px;
}

.card {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  flex: 1
}

.dlImage {
  height: 56px;
  width: 90px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
  position: relative
}

.dlImage:first-child {
  margin-right: 4px;
}

.dlImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}